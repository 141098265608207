import React, { Component } from 'react'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import PageIntro from '../components/PageIntro';
import PrincingList from '../components/PrincingList';
import '../../assets/main/css/pricing.css'

export class Pricing extends Component {
    render() {
        return (
            <>
                <Header />
                <PageIntro intro="Pricing Tables" design="pages-head2" title="" />
                <PrincingList />
                <Footer />
            </>
        )
    }
}

export default Pricing

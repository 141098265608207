import React, { Component } from 'react'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import News from '../components/News';
import Project from '../components/Project';
import PageIntro from '../components/PageIntro';
import '../../assets/main/css/project.css'
import AffiliateInfo from '../components/AffiliateInfo';
import QuickContact from '../components/QuickContact';

class Affiliate extends Component {
    render() {
        return (
            <>
                <Header />
                <PageIntro title="Earn with Us" design="pages-head2" intro="Affiliate Marketing" />                
                <AffiliateInfo />
                <QuickContact />
                <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
                <Footer /> 
            </>
        )
    }
}

export default Affiliate

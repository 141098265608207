import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import PageIntro from '../../main/components/PageIntro';
import Header from '../../main/components/layout/Header';
import Footer from '../../main/components/layout/Footer';
import EachFeed from '../components/EachFeed';
import SmallFeed from '../components/SmallFeed';
import '../../assets/main/css/news.css'

import { fetchFeeds } from '../../redux/actions/blogActions'
import { reduceArray,sortDesc, linkEncode,linkDecode } from '../../Funcs';

export class Tags extends Component {
    static propTypes = {
        feeds: PropTypes.array.isRequired
    }
    state = {
        all_feeds:[],
        tags:[],
        categories:[],
        latestFeeds:[],
        popularFeeds:[]
    }
    componentWillMount(){
        this.props.fetchFeeds()
    }
	componentDidMount= ()=>{
		setTimeout(this.updateStateDef,1000)
	}

	updateStateDef = () =>{
        let { feeds } =  this.props
        const tag_ = linkDecode(this.props.match.params.tag)
        var newFeeds = [...feeds].filter(feed => feed.tags.split(',').includes(tag_) )

        feeds = newFeeds.map(feed=>{
            return {...feed,link:linkEncode(feed.link)}
        })

        

        const latestFeeds = reduceArray(5,sortDesc([...feeds],"id") )
        const popularFeeds = reduceArray(5,sortDesc([...feeds],"views") )

		var all_category = [...new Set( [...feeds].map(feed => feed.category) )]
        var all_tags = []
        var allTags = feeds.map(feed =>{
            if(feed.tags != ""){
                var tags = feed.tags.split(',')
                all_tags = [...all_tags,...tags]
            }
        })
        all_tags = [...new Set( all_tags )]
        all_tags = [...all_tags].filter(atag => atag !== "")
        this.setState({
            all_feeds:reduceArray(5,feeds),
            popularFeeds,
            latestFeeds,
            tags:all_tags,
            categories:all_category
        })
    }

    render() {
        const {all_feeds,latestFeeds,popularFeeds,tags,categories} = this.state
        return (
            <>
                <Header />
                <PageIntro intro="News" design="pages-head1" title="Discover more"  />
                <section id="single-news-p1" className="single-news-p1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {
                                    all_feeds.map((feed,index)=>(
                                        <EachFeed feed={feed} key={index} />
                                    ))
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="small-news-box">
                                    <h4 style={{"color":"#777","fontWeight":"bold"}} className="pb-2">Popular Feeds</h4>
                                    {
                                        popularFeeds.map((feed,index)=>(
                                            <SmallFeed feed={feed} key={index} />
                                        ))
                                    }
                                    <div className="clearfix"></div>
                                    <h4 style={{"color":"#777","fontWeight":"bold"}} className="pb-2">Latest Feeds</h4>
                                    {
                                        latestFeeds.map((feed,index)=>(
                                            <SmallFeed feed={feed} key={index} />
                                        ))
                                    }
                                </div>
                                <div className="small-news-box">
                                    <div className="clearfix"></div>
                                    <div className="ad-box-sn"> 
                                        <h5 style={{"color":"#777","fontWeight":"bold"}} className="pb-2">Categories</h5><hr/>
                                        {
                                            categories.map( category =>(
                                                <div key={category}><Link to={`/blog/category/${category}`}  className="pb-2 category">{category}</Link><hr/></div>
                                            ))
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="ad-box-sn" style={{paddingBottom:"20px"}}> 
                                        <h5 style={{"color":"#777","fontWeight":"bold"}} className="pb-2">Tags</h5><hr/>
                                        {
                                            tags.map( tag =>(
                                                <Link to={`/blog/tag/${tag}`} className="badge" key={tag}>{tag}</Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer /> 
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    feeds: state.blog.feeds
  })
  
const mapDispatchToProps = {
    fetchFeeds
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags)

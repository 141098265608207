import React, { Component } from 'react'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import News from '../components/News';
import PageIntro from '../components/PageIntro';
import QuickContact from '../components/QuickContact';
import Faqs from '../components/Faqs';
import '../../assets/main/css/faq.css'

class FAQ extends Component {
    render() {
        return (
            <>
                <Header />
                <PageIntro intro="Our Most Frequently asked Questions" design="pages-head1" title="FAQ" />
                <Faqs />
                <QuickContact />
                <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
                <Footer />
            </>
        )
    }
}

export default FAQ

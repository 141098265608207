import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import PageIntro from '../components/PageIntro';
import News from '../components/News';
import QuickContact from '../components/QuickContact';
import AboutUs from '../components/AboutUs';
import '../../assets/main/css/about.css'

class About extends Component {
  render() {
    return (
      <> 
        <Header />
        <PageIntro intro="Myschoolgateway is a worthwhile service" design="pages-head1" title="Discover more about us" />
        <AboutUs />
        <QuickContact />
        <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
        <Footer />
      </>
    );
  }
}

export default About;
import React from 'react'
import { Link } from 'react-router-dom';
import { linkEncode } from '../../Funcs';

export default function SmallFeed(props) {
    const {title,date,link,image} = props.feed
    return (
        <>
           <Link to={linkEncode(link)}>
                <div className="right-side-sn-cont">
                <img src={`${process.env.PUBLIC_URL}/bucket/${image}`}  alt="" className="img-fluid" style={{marginBottom:"4px"}}/>
                <p>{title}</p>
                <small><i className="fa fa-clock-o"> {date}</i></small>
                </div>
            </Link> 
        </>
    )
}

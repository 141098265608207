import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {linkEncode, forceSecured, setCookie} from '../../../Funcs'
import { fetchSearch,fetchSchools,toggleSearch,recordVisit,setSGWSession } from '../../../redux/actions/mainActions'


import '../../../assets/main/css/bootstrap/bootstrap.min.css'
import '../../../assets/main/font-awesome-4.7.0/css/font-awesome.min.css'
import '../../../assets/main/css/animate/animate.min.css'
import '../../../assets/main/css/owl-carousel/owl.carousel.min.css'
import '../../../assets/main/css/owl-carousel/owl.theme.default.min.css'
import '../../../assets/main/css/style.css'
 

class Header extends Component {
	state = {
		displaySearch:false,
		displayNav:false,
		search:"",
		schools:[],
		search_list:[],
	}
	componentWillMount(){
		this.props.fetchSchools()
		recordVisit(window.location.pathname)
	}

	//clicking on this will hide or show the search box
	toggleSearch = () =>{
		if(this.props.showSearch){
			this.props.toggleSearch(false)
		}
		else{
			this.props.toggleSearch(true)
		}
		// this.setState((prevState) =>{
		// 	return {
		// 		displaySearch:!prevState.displaySearch
		// 	}
		// })
	}
	toggleNavBar = () =>{
		const {showSearch} = this.props
		const {displayNav} = this.state
		if(showSearch){
			this.setState({displayNav:false})
			this.props.toggleSearch(false)
		}
		else{
			this.setState({displayNav:!displayNav})
		}
	}

	openNavBar = () =>{
		this.setState({
				displayNav:true
		})
	}
	//changing the value of search will be getting values from database and updating the state with the values
	onChange = (e) =>{
		const {schools} = this.props
		const keyword = e.target.value.toLowerCase();
		let newSchools = schools.filter(school=>{
			if(school.name.toLowerCase().indexOf(keyword) != -1 || school.abbrev.toLowerCase().indexOf(keyword) != -1 || school.town.toLowerCase().indexOf(keyword) != -1){
				return school;
			}
		});
		this.setState({
			search_list:newSchools,
			search:e.target.value
		})
		// console.log("new schools:",newSchools)
	}

	onSearch = () =>{
		const {search,search_list} = this.state
		if(search != ""){
			var schoolInfo = [...this.props.schools].filter(school=> school.search.toLowerCase() == search.toLowerCase());
			if(schoolInfo.length > 0){
				setCookie("mysgwid",schoolInfo[0].mysgwid,1);
				window.location.assign("https://myschoolgateway.com/myschool/");
				// setSGWSession(schoolInfo[0].mysgwid);
				// window.location.assign(schoolInfo[0].link1)
				// console.log(schoolInfo[0].link1);
			}
			else{
				alert("No School Found");
			}
		}
		else{
			alert("Please search for a school");
		}		
		// console.log(search);
	}
	render() {
		// forceSecured();//force to a secured protocol
		const dropdownItem = (page) => {
			var currentPage = window.location.pathname.substr(1);
			if(page === currentPage){
				return "dropdown-item active"
			}
			else{
				return "dropdown-item"
			}			
		}

		const navItem = (page) => {
			var currentPage = window.location.pathname.substr(1);
			if(page === currentPage){
				return "nav-item active"
			}
			else{
				return "nav-item"
			}			
		}
		const { displaySearch,displayNav,search_list } = this.state
		const {showSearch} = this.props
		return (
			<>
			<header>
			<div className="top-menubar">
			  <div className="topmenu">
				<div className="container">
				  <div className="row">
					<div className="col-md-7">
					  <ul className="list-inline top-contacts">
						<li>
						  <i className="fa fa-envelope"></i> Email: <a href="mailto:myschoolgatewaywebmaster@gmail.com"><b>info@myschoolgateway.com</b></a>
						</li>
						<li>
						  <i className="fa fa-phone"></i> Hotline: <b>+234 903 9146 415</b>
						</li>
					  </ul>
					</div> 
					<div className="col-md-5">
					  <ul className="list-inline top-data">
						<li><a href="https://www.facebook.com/mysgw" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
						<li><a href="https://www.twitter.com/myschoolgateway" target="_empty"><i className="fa top-social fa-twitter"></i></a></li>
						<li><a href="https://www.Instagram.com/myschoolgateway" target="_empty"><i className="fa top-social fa-instagram"></i></a></li> 
						<li><a href="https://www.wa.me/2349039146415" target="_empty"><i className="fa top-social fa-whatsapp" ></i></a></li>
						{/* <li><a href="#" className="log-top" data-toggle="modal" data-target="#login-modal">Login</a></li>  */}
						<li><a className="log-top" href="https://www.myschoolgateway.com/registration.php">Register your School</a></li> 
					  </ul>
					</div>
				  </div>
				</div>
			  </div> 
			</div>  
			<nav className="navbar navbar-expand-lg navbar-light" id="mainNav" data-toggle="affix">
			  <div className="container">
				<Link className="navbar-brand smooth-scroll" to="/">
				  <img src={`${process.env.PUBLIC_URL}/assets/main/img/logo-s.png`} style={{width:"204px",height:"40px"}} alt="logo" />
				</Link> 
				{/* ref={input => this.openNavBar = input}  */}
				<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>this.toggleNavBar()}> 
						<span>
							<img src={`${process.env.PUBLIC_URL}/assets/main/img/menu.png`} style={{width:"30px",height:"30px"}}  alt="Menu" />
							<span style={{ position: 'absolute', top: 15, right: 17, width:10, height:10, backgroundColor:'red', borderRadius:50 }}></span>
						</span>
					  {/* <span className="navbar-toggler-icon"></span> */}
				</button>  
				<div className="collapse navbar-collapse" id="navbarResponsive"  style={{"display": displayNav || showSearch ?"block":"none"}}>
				  <ul className="navbar-nav ml-auto">
					  <li className={navItem("")} ><Link className="nav-link smooth-scroll" to="/">Home</Link></li>
					  <li className="nav-item dropdown" >
						<a className="nav-link dropdown-toggle smooth-scroll" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About</a> 
						<div className="dropdown-menu dropdown-cust" aria-labelledby="navbarDropdownMenuLink">
							<Link className={dropdownItem("about-us")} to="/about-us">About Us</Link>
						  <a href="https://www.youtube.com/channel/UC-CeX2ZhgV3jziC1EpuzSGw" className={dropdownItem("videos")} >Videos</a>
						  <Link className={dropdownItem("pricing")} to="/pricing">Pricing Tables</Link>
							<Link className={dropdownItem("FAQs")} to="/FAQs">FAQ</Link>
							<Link className={dropdownItem("testimonials")} to="/testimonials">Testimonials</Link>
						</div>
					  </li>
					  <li className={navItem("contact-us")} ><Link className="nav-link smooth-scroll" to="/contact-us">Contact</Link></li> 
					  {/* <li className={navItem("about-us")} ><Link className="nav-link smooth-scroll" to="/about-us">About</Link></li>  */}
					  <li className={navItem("blog")} ><Link className="nav-link smooth-scroll" to="/blog">Blog</Link></li> 
					  <li className="nav-item" ><a className="nav-link smooth-scroll" href="https://www.myschoolgateway.com/registration.php">Register</a></li>
					  <li className="nav-item" ><Link className="nav-link smooth-scroll" to="/affiliates">Affiliate</Link></li>
					  {/* <li className="nav-item dropdown" >
						<a className="nav-link dropdown-toggle smooth-scroll" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages</a> 
						<div className="dropdown-menu dropdown-cust" aria-labelledby="navbarDropdownMenuLink">
						  <Link className={dropdownItem("projects")} to="/projects">Project</Link>
						  <Link className={dropdownItem("contact-us")} to="/contact-us">Contact Us</Link>
						  <Link className={dropdownItem("comming-soon")} to="/comming-soon">Comming Soon</Link>
						  <Link className={dropdownItem("pricing")} to="/pricing">Pricing Tables</Link>
						  <Link className={dropdownItem("404")} to="/404">404</Link>
							<Link className={dropdownItem("services")} to="/services">Services</Link>
							<Link className={dropdownItem("careers")} to="/careers">Career Opportunity</Link>
							<Link className={dropdownItem("team")} to="/team">Meet Our Team</Link>
							<Link className={dropdownItem("FAQs")} to="/FAQs">FAQ</Link>
							<Link className={dropdownItem("testimonials")} to="/testimonials">Testimonials</Link>
						</div>
					  </li> */}
					  <li>
						<i className="search fa fa-search search-btn" onClick={()=>this.toggleSearch()}></i>
						<div className="search-open" style={{"display": showSearch?"block":"none"}}>
						  <div className="input-group animated fadeInUp">
							<input type="text" list="schools" className="form-control" placeholder="Search" onChange={this.onChange} name="search" aria-describedby="basic-addon2"/>
							<span className="input-group-addon" id="basic-addon2" style={{cursor:"pointer"}} onClick={this.onSearch}>Go</span>
						  </div>
							<datalist id="schools">								
									{
										search_list.map((school,index) =>{
										if(index < 5){//minimze display data to 5
											return <option key={index} value={school.search} />
										}
										// (
										// 	<option key={index} value={school.search} />
										// )
										})
									}
							</datalist>
						</div>
					  </li> 
					  <li>
						{/* <div className="top-menubar-nav">
						  <div className="topmenu ">
							<div className="container">
							  <div className="row">
								<div className="col-md-9">
								  <ul className="list-inline top-contacts">
									<li>
									  <i className="fa fa-envelope"></i> Email: <a href="mailto:info@htmlstream.com">info@htmlstream.com</a>
									</li>
									<li>
									  <i className="fa fa-phone"></i> Hotline: (1) 396 4587 99
									</li>
								  </ul>
								</div> 
								<div className="col-md-3">
								  <ul className="list-inline top-data">
									<li><a href="#" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
									<li><a href="#" target="_empty"><i className="fa top-social fa-twitter"></i></a></li>
									<li><a href="#" target="_empty"><i className="fa top-social fa-google-plus"></i></a></li> 
									<li><a href="#" className="log-top" data-toggle="modal" data-target="#login-modal">Login</a></li>  
								  </ul>
								</div>
							  </div>
							</div>
						  </div> 
						</div> */}
					  </li>
				  </ul>  
				</div>
			  </div>
			</nav>
		  </header> 
		  




		  {/* <section id="login">
				<div className="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{'display': 'none'}}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header" align="center">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span className="fa fa-times" aria-hidden="true"></span>
								</button>
							</div>
							<div id="div-forms">
								<form id="login-form">
									<h3 className="text-center">Login</h3>
									<div className="modal-body">
										<label for="username">Username</label> 
										<input id="login_username" className="form-control" type="text" placeholder="Enter username " required />
										<label for="username">Password</label> 
										<input id="login_password" className="form-control" type="password" placeholder="Enter password" required />
										<div className="checkbox">
											<label>
												<input type="checkbox" /> Remember me
											</label>
										</div>
									</div>
									<div className="modal-footer text-center">
										<div>
											<button type="submit" className="btn btn-general btn-white">Login</button>
										</div>
										<div>
											<button id="login_register_btn" type="button" className="btn btn-link">Register</button>
										</div>
									</div>
								</form>
								<form id="register-form"  style={{'display': 'none'}}>
									<h3 className="text-center">Register</h3>
									<div className="modal-body"> 
										<label for="username">Username</label> 
										<input id="register_username" className="form-control" type="text" placeholder="Enter username" required/>
										<label for="register_email">E-mailId</label> 
										<input id="register_email" className="form-control" type="text" placeholder="Enter eMail" required/>
										<label for="register_password">Password</label> 
										<input id="register_password" className="form-control" type="password" placeholder="Password" required/>
									</div>
									<div className="modal-footer">
										<div>
											<button type="submit" className="btn btn-general btn-white">Register</button>
										</div>
										<div>
											<button id="register_login_btn" type="button" className="btn btn-link">Log In</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section> */}

		</>
		)
	  }
	}
	

	const mapStateToProps = (state) => ({
	  search: state.main.search,
	  schools: state.main.schools,
	  showSearch: state.main.showSearch,
	})
	
	const mapDispatchToProps = {
		fetchSearch,fetchSchools,toggleSearch
	}
	
	export default connect(mapStateToProps, mapDispatchToProps)(Header)
import React from 'react'
import { Link } from 'react-router-dom';
import '../../assets/main/css/com-soon.css'

export default function ComSoon() {
    return (
        <>
        {/* this only work when the page loads. The timer will not work if the page is navigated using link */}
            <section className="hero-area bg-1">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block text-center">
                                <h1 className="wow fadeInDown" data-wow-delay="0.3s" data-wow-duration=".2s">Comming soon new product</h1>
                                <p className="wow fadeInDown" data-wow-delay="0.5s" data-wow-duration=".5s">Real world Business solution for your Brand Growth.</p>
                            </div>
                        </div>
                        <div className="col-md-12 wow zoomIn">
                            <div className="block">
                                <div className="counter text-center">
                                    <ul id="countdown_dashboard">
                                        <li>
                                            <div className="dash days_dash">
                                                <div className="digit">0</div>
                                                <div className="digit">0</div>
                                                <div className="digit">0</div>
                                                <span className="dash_title">Days</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dash hours_dash">
                                                <div className="digit">0</div>
                                                <div className="digit">0</div>
                                                <span className="dash_title">Hours</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dash minutes_dash">
                                                <div className="digit">0</div>
                                                <div className="digit">0</div>
                                                <span className="dash_title">Minutes</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dash seconds_dash">
                                                <div className="digit">0</div>
                                                <div className="digit">0</div>
                                                <span className="dash_title">Seconds</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="wow fadeInDown text-center" data-wow-delay="0.7s" data-wow-duration=".7s">
                            <Link className="btn btn-general btn-green" to="/contact-us" role="button">Contact Us</Link>
                            {/* <a className="btn btn-general btn-green" href="#about">Contact Us</a> */}
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>   
        </>
    )
}

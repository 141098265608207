import React, { Component } from 'react'
import { sendFeedBack } from '../../redux/actions/mainActions'

class QuickContact extends Component {
    state = {
        fullname:"",
        email:"",
        mobile:"",
        subject:"",
        message:"",
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    onSubmitForm = (e) =>{
        e.preventDefault();
        const {fullname,email,mobile,subject,message} = this.state
        sendFeedBack(fullname,email,mobile,subject,message,"Myschoolgateway");
        this.setState({
            fullname:"",
            email:"",
            mobile:"",
            subject:"",
            message:"",
        });
    }

    render() {
        const {fullname,mobile,email,subject,message} = this.state
        return (
            <>
                <div className="overlay-contact-h"></div>
                <section id="contact-h" className="bg-parallax contact-h-bg">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6">
                            <div className="contact-h-cont">
                            <h3 className="cl-white">Continue The Conversation</h3><br/>
                            <form onSubmit={this.onSubmitForm}>
                                <div className="form-group cl-white">
                                    <label for="name">Your Fullname</label>
                                    <input type="text" className="form-control" name="fullname" value={fullname} onChange={this.onChange} aria-describedby="nameHelp" placeholder="Enter name" required/> 
                                </div>  
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group cl-white">
                                            <label for="mobile">Mobile Number</label>
                                            <input type="text" className="form-control" name="mobile" value={mobile} onChange={this.onChange} aria-describedby="mobileHelp" placeholder="Enter your mobile" required/> 
                                        </div>                          
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group cl-white">
                                            <label for="exampleInputEmail1">Email address</label>
                                            <input type="email" className="form-control" name="email" value={email} onChange={this.onChange} aria-describedby="emailHelp" placeholder="Enter email" /> 
                                        </div> 

                                    </div>
                                </div>
                                <div className="form-group cl-white">
                                    <label for="subject">Subject</label>
                                    <input type="text" className="form-control" name="subject" value={subject} onChange={this.onChange} aria-describedby="subjectHelp" placeholder="Enter subject" required/> 
                                </div>  
                                <div className="form-group cl-white">
                                    <label for="message">Message</label>
                                    <textarea className="form-control" placeholder="Write your message.." name="message" rows="3" value={message} onChange={this.onChange} required></textarea>
                                </div>  
                                <button type="submit" className="btn btn-general btn-white" role="button"><i fa fa-right-arrow></i>GET CONVERSATION</button>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>         
                </section> 
            </>
        )
    }
}

export default QuickContact

import React, { Component } from 'react'
import c from './redux/actions/main.types'
const link = c.MAIN_ONLINE

//creating a link that will replace space with -
export const linkEncode = (link) =>{
    link = encodeURI(link);
    return link.replace(new RegExp("%20","g"),"-");
    // link = link.replace(new RegExp("%20","g"),"-");
    // return `${process.env.PUBLIC_URL}/${link}`;
}
//decodeing encoded uri
export const linkDecode = (link) =>{
    link = link.replace(new RegExp("-","g"),"%20"); 
    return decodeURI(link);   
}

//using the average rate of a product to display the numbe of rates
export const productRating = (rate) => {
    var num = Math.floor(rate)
    var fullStar = [];
    var halfStar = [];
    var emptyStar = [];
    var remaining = (5 - Math.round(rate) ) //the remain stars(empty star) in case if the rate is less than 4
    //full stars 
    for (let i = 0; i < num; i++) {
        fullStar.push(<i className="fa fa-star checked"></i>)
    }
    //half star it should be only one if available
    if(rate < 5 && (rate % 1 === 0.5 )){
        halfStar.push(<i className="fa fa-star-half-o checked"></i>)
    } 
    //empty stars if available
    if(rate < 5 && remaining > 0){ 
        for (let i = 0; i < remaining; i++) {               
            emptyStar.push(<i className="fa fa-star-o empty checked"></i>)
        }
    }
    //return the concatination of all the arrays 
    return [...fullStar,...halfStar,...emptyStar]
}
//subscribe to newsletter
export const subscribe = (email,page) =>{
    fetch(`${link}?subscribe&email=${email}&page=${page}`)
    .then(res => res.json())
    .then(msg => {
        if(msg == 1){
            alert("You have successfully suscribed to our newsletter. You can unsuscribe anytime. Thanks!!")
        }
        else{
            alert("Failed to subscri")
        }
    })
}
//reduce the size of an array
export const reduceArray = (stop,arr) =>{
    if(stop < arr.length){
        var newArr = [];
        for (let i = 0; i < arr.length; i++) {
            newArr.push(arr[i]);    
            if(i+1 === stop) break;            
        }
        return newArr;
    }
    else{
        return arr;
    }
}
//search to know if an element of an array exist in another array
//if seaching for numbers, make sure that the value in main contains numbers
export const searchArray = (main,search) => {
    search = (typeof(search) ===  "object")?search:search.split(',')
    var retBool = false;
    for (let i = 0; i < search.length; i++) {
        if( main.includes( search[i]) ){
            retBool = true
            break;
        }	
    }
    return retBool
}
//generate aside feeds such that it will return the exact number of feeds need even if the selected feeds is not up to the number, 
//it will add a specific number of other feeds to augment it
export const getAsideFeeds = (allFeeds,selectedFeeds,type,num) =>{
    //this will return the top num eg(5) selected feeds by a custom type(popular/latest)
    var retFeeds = reduceArray(num,sortAsc(selectedFeeds,type))
    //in other to make sure at least num eg(5) feeds must be fetched remove the retFeeds from allFeeds and return the remaining as
    //otherFeeeds then add num -1 eg (4) otherFeeds to retFeeds and reduce it to num eg(5) arrays to return maximum of num eg(5) arrays.
    //num -1 eg (4) feeds will be added because for a tag or category to exist it must appear on at least one feed
    var retFeedsIDs = [...new Set( [...retFeeds].map(feed => feed.id ))]
    var otherFeeds = [...allFeeds].filter(feed => !retFeedsIDs.includes(feed.id))
    retFeeds = [...retFeeds,...otherFeeds]
    retFeeds = reduceArray(num,retFeeds)
    return retFeeds
}
//generate arrays using numbers
export const genArr = (first,last) =>{
    var diff = last - first;
    var ret = [];
    for (let i = 0; i < diff + 1; i++) {
        ret.push(first + i)            
    }
    return ret;
}
//renumber pages
export const setNewPages = (data) =>{
    var num = 0;
    var setPages = []
    for (let i = 0; i <  [...data].length; i++) {
    	let feed = data[i];
    	feed.page = Math.floor(num / 5) + 1;
    	num++
    	setPages.push(feed)
    }
    return [...setPages]
}
//sort in descending order so that bigger ones will be first
export const sortDesc = (feeds,by) =>{    
    var newFeeds = feeds.sort((a,b)=> b[by] - a[by])
    return newFeeds
}
//sort in ascending order so that smaller ones will be first
export const sortAsc = (feeds,by) =>{    
    var newFeeds = feeds.sort((a,b)=> a[by] - b[by])
    return newFeeds
}
//format views to display m,k for millions and thousand views
export const formatViews = (num) =>{
    var count = num.length;
	var conv = "";
	if(count == 4){	
		let first = num.substr(0, 1);
		let second = num.substr(1, 1);
		second >= 1 ? conv += `${first}.${second}k` : conv += `${first}k`;		
	}
	else if(count == 5){
		let first = num.substr(0, 2);
		let second = num.substr(2, 1);
		second >= 1 ? conv += `${first}.${second}k` : conv += `${first}k`;
	}
	else if(count == 6){
		let first = num.substr(0, 3);
		let second = num.substr(3, 1);
		second >= 1 ? conv += `${first}.${second}k` : conv += `${first}k`;
	}
	else if(count == 7){
		let first = num.substr(0, 1);
		let second = num.substr(1, 1);
		second >= 1 ? conv += `${first}.${second}m` : conv += `${first}m`;
	}		
	else if(count == 8){
		let first = num.substr(0, 2);
		let second = num.substr(2, 1);
		second >= 1 ? conv += `${first}.${second}m` : conv += `${first}m`;
	}
	else if(count == 9){
		let first = num.substr(0, 3);
		let second = num.substr(3, 1);
		second >= 1 ? conv += `${first}.${second}m` : conv += `${first}m`;
	}
	else if(count == 10){
		let first = num.substr(0, 1);
		let second = num.substr(1, 1);
		second >= 1 ? conv += `${first}.${second}b` : conv += `${first}b`;
	}	
	else{
        conv = num;
    }
	return conv;
}
//get headers meta tag
export const getMeta = (metaName) => {
    var metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      let meta = metas[i];
      if(meta.getAttribute('name') === metaName){
        return meta.getAttribute('content');
      }        
    }
  }
//change headers meta tag
export const changeMeta = (metaName,newContent) => {
    var metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      let meta = metas[i];
      if(meta.getAttribute('name') === metaName){
        meta.setAttribute('content',newContent);
      }        
    }
  }

//change headers meta tag
export const forceSecured = () => {
    if(window.location.protocol === "http:"){
        var url = window.location.href
        var removeHttp = url.substr(4,url.length)       
        window.location.href = "https"+removeHttp;
    }
  }
  























//sort the views in descending order so that big ones will be first
export const popularProducts = (products) =>{    
    var newProducts = products.sort((a,b) =>(a.views < b.views)?1:((b.views < a.views) ? -1 : 0 ))
    return newProducts
}

//sort the date_Added in descending order so that new ones will be first
export const latestProducts = (products) =>{    
    var newProducts = products.sort((a,b) =>(a.date_added < b.date_added)?1:((b.date_added < a.date_added) ? -1 : 0 ))
    return newProducts
}

//get the name of a color using the code
export const getColorName = (code,colorList) => {
    let colorArr = colorList.filter(color =>( color.code === code))
    colorArr = colorArr.map(color => color.name)
    return colorArr[0]

}

//formatting text for big banners
export const textFormatter = (settings) => {
    if(settings.size == 'bigger'){
        return <h1 className="" style={{color:settings.color}}> {settings.text} </h1>
    }            
    else if(settings.size == 'big'){
        return <h2 className="" style={{color:settings.color}}> {settings.text} </h2>
    }        
    else if(settings.size == 'smaller'){
        return <h3 className="" style={{color:settings.color}}> {settings.text} </h3>
    }                    
    else{
        return <h4 className="" style={{color:settings.color}}> {settings.text} </h4>
    }
}

//return the price and the currency of an item such that change in currency will change the price and currency of all items
export const getItemPrice = (num,currencies) =>{
    let activeCurrency = currencies.filter( currency => currency.active === true );
    let convertRate = activeCurrency.map( currency => currency.conversion);
    let symbol = activeCurrency.map( currency => currency.symbol);
    convertRate = convertRate[0];
    symbol = symbol[0];
    let price = Number(convertRate) * Number(num)
    price = Math.round(price * 100)/100
    // price = price.toFixed(2)
    price = price.toLocaleString();// introducing a thousand comma seperator to price
    return symbol+price;
}
//returning new price and old price of a product
export const myPrice = (price,percentOff,timer) =>{
    if(timer > Date.now()){
        if(percentOff){
            percentOff = percentOff * -1;
            let discount = percentOff / 100;
            discount = discount * price;
            let newPrice = price - discount;
            return {newPrice:newPrice, oldPrice:price};
        }
        else{
            return {newPrice:price, oldPrice:null};
        }
    }
    else{
        return {newPrice:price, oldPrice:null};
    }
  }
  //show the countdown of a promo product till expiring date
  export const countDown = (timer,now,value) =>{
    // if(timer > Date.now()){
    // now = Date.now();
   
    //  var ret = setInterval(() => {
        // console.log(timer)
        now = Date.now();
        if(timer > now){
            //RMS: Remaining Miliseconds; now: date.now(); ED: Expiration date
            let aMinute = (1000 * 60);
            let anHour = (aMinute * 60);
            let aDay = (anHour * 24);

            let RMS = timer - now;
            // let RMS = timer - Date.now();
            var days = Math.floor(RMS / aDay)
            var hours = Math.floor((RMS % aDay) / anHour);
            var mins = Math.floor((RMS % anHour) / aMinute);
            var secs = Math.floor((RMS % aMinute) / 1000);
            days = days < 10?'0'+days:days;
            hours = hours < 10?'0'+hours:hours;
            mins = mins < 10?'0'+mins:mins;
            secs = secs < 10?'0'+secs:secs;
            if(value === 'day'){
                return days
            }
            else if(value === 'hour'){
                return hours
            }
            else if(value === 'min'){
                return mins
            }
            else{
                // console.log(secs)
                return secs
            }
        }
        else{
            // console.log(0)
            return '00';
        }
    // }, 1000);
    // setInterval(() => {
    //     return ret
    // }, 1000);
    
    // return ret
    // if(value === 'day'){
    //     return days
    // }
    // else if(value === 'hour'){
    //     return hours
    // }
    // else if(value === 'min'){
    //     return mins
    // }
    // else{
    //     return secs
    // }
  }
  export const countDown1 = (timer,now,value,callback) =>{
    // if(timer > Date.now()){
    // now = Date.now();
   
     var countDown1 = setInterval(() => {
        // console.log(timer)
        now = Date.now();
        if(timer > now){
            //RMS: Remaining Miliseconds; now: date.now(); ED: Expiration date
            let aMinute = (1000 * 60);
            let anHour = (aMinute * 60);
            let aDay = (anHour * 24);

            let RMS = timer - now;
            // let RMS = timer - Date.now();
            var days = Math.floor(RMS / aDay)
            var hours = Math.floor((RMS % aDay) / anHour);
            var mins = Math.floor((RMS % anHour) / aMinute);
            var secs = Math.floor((RMS % aMinute) / 1000);
            days = days < 10?'0'+days:days;
            hours = hours < 10?'0'+hours:hours;
            mins = mins < 10?'0'+mins:mins;
            secs = secs < 10?'0'+secs:secs;
            if(value === 'day'){
                return days
            }
            else if(value === 'hour'){
                return hours
            }
            else if(value === 'min'){
                return mins
            }
            else{
                // console.log(secs)
                callback(secs)
            }
        }
        else{
            // console.log(0)
            return '00';
        }
    },1000)
}
export const git = (limit,callback) =>{
    var i = 0;
     var git = setInterval(() =>{
        // console.log(j)
        callback(i)
        // return 
        // if(i === limit - 1){
        //     clearInterval(git)            
        // }
        i++
    },800)
    // console.log(git)
    // return git
}
//check to see if product timer is over
export const checkTimer = (timer,now) => {
   return (timer > now)?true:false;
}
//generate fake array to map through it
export const genFakeArray = (num) =>{
    var arr = []
    num = Math.floor(num)
    for (let i = 0; i < num; i++) {
        arr.push({id:i,arthur:'okpaleke34'})            
    }
    return arr
}

//set new cookie
export const setCookie = (name,value,days) => {
    var expires = "";
    if(days){
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires="+date.toUTCString();
    }
    document.cookie = name +  "=" + (value || "") + expires + "; path=/";
}
//get a cookie with name
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while(c.charAt(0) == ' ') c = c.substring(1,c.length);
        if(c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return '';//null
}
//add new compare to cookie 
export const addItemToCompare = (id,name) =>{    
    var ids = getCookie(name).split(',')
    if(ids.length < 3){
        ids.push(id)
        ids = ids.filter(id_ => id_ !== "")//remove empty on first addition
        ids = [... new Set(ids)] //return unique ids
        ids = ids.join()
        setCookie('compare-list',ids,180)
        return ids
    }
    else{
        return 'full'
        // return false
    }
}
export const eraseCookie = (name) => {
    document.cookie = name+'=; Max-Age=-999999;';
}
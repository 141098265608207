import React, { Component } from 'react';
import { sendFeedBack } from '../../redux/actions/mainActions'

class ContactBox extends Component {
    state = {
        fullname:"",
        email:"",
        mobile:"",
        subject:"",
        message:"",
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    onSubmitForm = (e) =>{
        e.preventDefault();
        const {fullname,email,mobile,subject,message} = this.state
        sendFeedBack(fullname,email,mobile,subject,message,"Contact us");
        this.setState({
            fullname:"",
            email:"",
            mobile:"",
            subject:"",
            message:"",
        });
    }
  render() {
    const {fullname,mobile,email,subject,message} = this.state
    return (
      <>
        {/* <!--====================================================
                                CONTACT-P1  
        ======================================================-->  */}
            <section id="contact-p1" className="contact-p1"> 
            <div className="container">
                <div className="row">
                <div className="col-md-8">
                    <div className="contact-p1-cont">
                    <h3>Contact our experienced team</h3>
                    <div className="heading-border-light"></div> 
                    <p>
                        It is a long established fact that a reader might fail to understand some content of a page or might feel like to ask more on something that is not explained in full.<br/>
                        Drop a message for us, on enquiry or problem you are facing on myschoolgateway platform
                    </p>
                    </div>
                </div>
                <div className="col-md-4"> 
                    <div className="contact-p1-cont2"> 
                    <address className="address-details-f">
                        Opp. Total filling station <br/>
                        Awka-etiti Ekwulobia Road, Awka-etiti. <br/>
                        Idemili South, Anambra State, Nigeria. <br/>
                        Phone: +234 903 9146 415<br/>                        
                        Email: <br/>
                        <a href="mailto:myschoolgatewaywebmaster@gmail.com" className="">info@myschoolgateway.com</a><br/>                        
                        <a href="mailto:myschoolgatewaywebmaster@gmail.com" className="">support@myschoolgateway.com</a>
                    </address>
                    <ul className="list-inline social-icon-f top-data">
                        <li><a href="https://www.facebook.com/mysgw" target="_empty"><i className="fa top-social fa-facebook" style={{"height": "35px", "width":"35px", "lineHeight": "35px"}}></i></a></li>
                        <li><a href="https://www.twitter.com/myschoolgateway" target="_empty"><i className="fa top-social fa-twitter" style={{"height": "35px", "width":"35px", "lineHeight": "35px"}}></i></a></li>
                        <li><a href="https://www.Instagram.com/myschoolgateway" target="_empty"><i className="fa top-social fa-instagram" style={{"height": "35px", "width":"35px", "lineHeight": "35px"}}></i></a></li> 
                        <li><a href="https://www.wa.me/2349039146415" target="_empty"><i className="fa top-social fa-whatsapp" style={{"height": "35px", "width":"35px", "lineHeight": "35px"}}></i></a></li> 
                    </ul>
                    </div>
                </div>  
                </div>
            </div>
            </section>

            {/* <!--====================================================
                                    CONTACT-P2 
            ======================================================-->  */}
            <service className="contact-p2" id="contact-p2">
            <div className="container">
                <form onSubmit={this.onSubmitForm} className="row con-form">
                    <div className="col-md-12">
                    <input type="text" name="fullname" value={fullname} onChange={this.onChange}  placeholder="Fullname" className="form-control" required/>
                    </div><br/><br/>
                    <div className="col-md-6">
                    <input type="email" name="email" value={email} onChange={this.onChange} placeholder="Email Address" className="form-control"/>
                    </div>
                    <div className="col-md-6">
                    <input type="text" name="mobile" value={mobile} onChange={this.onChange} placeholder="Mobile Number" className="form-control" required/>
                    </div><br/><br/>
                    <div className="col-md-12">
                    <input type="text" name="subject" value={subject} onChange={this.onChange} placeholder="Subject" className="form-control"/>
                    </div>
                    <div className="col-md-12"><textarea name="message" value={message} onChange={this.onChange}></textarea></div>
                    <div className="col-md-12 sub-but"><button type="submit" className="btn btn-general btn-green" role="button">Send Message</button></div>
                </form>
            </div>
            </service>

      </>
    );
  }
}

export default ContactBox;

import React from 'react'

export default function PrincingList() {
    return (
        <>
           <section id="price">  
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable">
                                <div className="pricing-icon">
                                    <i className="fa fa-rocket"></i>
                                </div>
                                <div className="price-Value">
                                    <span className="currency">&#8358;</span>200
                                </div>
                                <span className="month">per student</span>
                                <div className="pricingHeader">
                                    <h3 className="title">Popular</h3>
                                </div>
                                <div className="pricing-content">
                                    <ul>
                                        <li>Scratch Card</li>
                                        <li>Student Dashboard</li>
                                        <li>Chat System</li>
                                        <li>Mobile Access</li>
                                    </ul>
                                </div>
                                <a href="https://www.myschoolgateway.com/registration.php" className="pricingTable-signup">Register</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable active">
                                <div className="pricing-icon">
                                    <i className="fa fa-globe"></i>
                                </div>
                                <div className="price-Value">
                                    <span className="currency">&#8358;</span>19,850
                                </div>
                                <span className="month">per year</span>
                                <div className="pricingHeader">
                                    <h3 className="title">Standard</h3>
                                </div>
                                <div className="pricing-content">
                                    <ul>
                                        <li>Personal Website</li>
                                        <li>SSL Certificate</li>
                                        <li>Customized Email</li>
                                        <li>Hosting and Web Plugins</li>
                                    </ul>
                                </div>
                                <a href="https://www.myschoolgateway.com/registration.php" className="pricingTable-signup">Register</a>                                
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable">
                                <div className="pricing-icon">
                                    <i className="fa fa-briefcase"></i>
                                </div>
                                <div className="price-Value">
                                    <span className="currency">&#8358;</span>150
                                </div>
                                <span className="month">per student</span>
                                <div className="pricingHeader">
                                    <h3 className="title">Novice</h3>
                                </div>
                                <div className="pricing-content">
                                    <ul>
                                        <li>No Scratch Card</li>
                                        <li>Student Dashboard</li>
                                        <li>Chat System</li>
                                        <li>Mobile Access</li>
                                    </ul>
                                </div>
                                <a href="https://www.myschoolgateway.com/registration.php" className="pricingTable-signup">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}

import c from '../actions/main.types'

const initialState = {
    schools: [],
    experts:[],
    showSearch:false,
    search:[],
    fullSearch:[],
    socials:{},
}
export default function(state = initialState, action){
    switch(action.type){
        case c.FETCH_SCHOOLS:
            return {
                ...state,
                schools: action.payload
            } 
        case c.TOGGLE_SEARCH:
            return {
                ...state,
                showSearch: action.payload
            }
        case c.FETCH_EXPERTS:
            return {
                ...state,
                experts: action.payload
            }
        case c.FETCH_SEARCH:
            return{
                ...state,
                search:action.payload
            }

        case c.FETCH_SEARCH_DETAILS:
            return{
                ...state,
                fullSearch:action.payload
            }

        case c.FETCH_SOCIALS:
            return{
                ...state,
                socials:action.payload
            }
        default:
            return state
    }
}
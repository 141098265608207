import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleSearch } from '../../redux/actions/mainActions'
import parser from 'html-react-parser';
import Features from './Features';

export class HomeBanner extends Component {
    //clicking on this will hide or show the search box
	toggleSearch = () =>{
		if(this.props.showSearch){
			this.props.toggleSearch(false)
		}
		else{
			this.props.toggleSearch(true)
		}
	}
    render() {
		const {showSearch} = this.props
        return (
            <>
            {/* <!--====================================================
                         HOME
            ======================================================--> */}
            <section id="home">
                <div id="carousel" className="carousel slide carousel-fade" data-ride="carousel"> 
                    {/* <!-- Carousel items --> */}
                    <div className="carousel-inner">
                        <div className="carousel-item active slides">
                        <div className="overlay"></div>
                        <div className="slide-1"></div>
                            <div className="hero ">
                            <hgroup className="wow fadeInUp">
                                {/* <h1>We Help <span ><a href="" className="typewrite" data-period="2000" data-type='[ " Business", " Startup"]'> */}
                                <h1>We Offer Quality <span ><a href="" className="typewrite" data-period="2000" data-type='[  " School"," Services"]'>
                                    <span className="wrap"></span></a></span> </h1>        
                                <h3>The next big idea is waiting for its next big changer</h3>
                            </hgroup>
                            <button className="btn btn-general btn-green wow fadeInUp" role="button" onClick={()=>this.toggleSearch()}>{showSearch?parser("<i class='fa fa-times'></i> Close Search"):parser("<i class='fa fa-search '></i> Search For Your School")}</button>
                            </div>           
                        </div> 
                    </div> 
                </div> 
            </section> 

            {/* <!--====================================================
                                    ABOUT
            ======================================================--> */}
            <section id="about" className="about">
            <div className="container">
                <div className="row title-bar">
                    <div className="col-md-12">
                        <h1 className="wow fadeInUp">We committed to helping</h1>
                        <div className="heading-border"></div>
                        <p className="wow fadeInUp" data-wow-delay="0.4s">Myschoolgateway is on online school system that is aimed to reduce the stress, nightmare and severe headaches teachers and school administrators undergo in managing and calculating the result of students. We provides many school services ranging from calculating the results of the students, checking on student ability and improvement over the years, giving comments on the improvements, checking on the strength of a subject teacher and comparing it with previous teachers, tracking the improvement in the school growth, population and education wise.</p>
                    </div>
                </div>
                <Features />
            </div>  
            {/* <!-- About right side withBG parallax --> */}
            {/* <div className="container-fluid">
                <div className="row"> 
                <div className="col-md-4 bg-starship">
                    <div className="about-content-box wow fadeInUp" data-wow-delay="0.3s">
                    <i className="fa fa-snowflake-o"></i>
                    <h5>Thoughts Leadership Platform</h5>
                    <p className="desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>
                </div> 
                <div className="col-md-4 bg-chathams">
                    <div className="about-content-box wow fadeInUp" data-wow-delay="0.5s">
                    <i className="fa fa-circle-o-notch"></i>
                    <h5>Corporate world Platform</h5>
                    <p className="desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>
                </div> 
                <div className="col-md-4 bg-matisse">
                    <div className="about-content-box wow fadeInUp" data-wow-delay="0.7s">
                    <i className="fa fa-hourglass-o"></i>
                    <h5>End to End Testing Platform</h5>
                    <p className="desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>
                </div> 
                </div> 
            </div>        */}
            </section>  
            
        </>
        )
    }
}

const mapStateToProps = (state) => ({    
	showSearch: state.main.showSearch,
})

const mapDispatchToProps = {
    toggleSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner)

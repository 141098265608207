import React from 'react'
import { Link } from 'react-router-dom';
import EachTestimony from './EachTestimony';

export default function HomeService(props) {
    return (
        <>
            {/* <!--====================================================
                   SERVICE-HOME
            ======================================================-->  */}
            <section id="service-h">
                    <div className="container-fluid">
                    <div className="row" >
                        <div className="col-md-6" >
                        <div className="service-himg" > 
                            {/* <iframe src="https://www.youtube.com/embed/754f1w90gQU?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe> */}
                            {/* https://www.youtube.com/watch?v=p6-ugDLyBjE */}
                            <iframe src="https://www.youtube.com/embed/Bt0wfhF8vrk?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>
                        </div>
                        </div>
                        <div className="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-h-desc">
                            <h3>We are Providing great Services</h3>
                            <div className="heading-border-light"></div> 
                            <p>These are three main education related services offered by myschoolgateway. Our goal is to give you a premium service at an affordable rate. Explore more.</p>  
                        <div className="service-h-tab"> 
                            <nav className="nav nav-tabs" id="myTab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-expanded="true">School Portal</a>
                            <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile">Sratch Cards</a> 
                            <a className="nav-item nav-link" id="my-profile-tab" data-toggle="tab" href="#my-profile" role="tab" aria-controls="my-profile">Study Abroad</a> 
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><p>We are number one school portal provider in Nigeria. We have years of experience and we offer services that are second to none. Visit our<Link to="/pricing"> pricing tables </Link> to know the cost of our services. Our goal is to reduce stress and automate most of the school services moreover bringing the students closer to good use of the internet</p></div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <p>Apart from running a school portal we also sell WAEC, NECO, NABTEB scratch cards at affordable prices. You can use our <Link to="/contact-us">contact us</Link> page to get this service. Remember to state properly what you want. We will coninue the chat with you once we get the message</p>
                            </div> 
                            <div className="tab-pane fade" id="my-profile" role="tabpanel" aria-labelledby="my-profile-tab">
                                <p>We make applications to many schools in North American, European and Some Asian countries. We also offer services like airport picking and hostel bookings to help students land safely abroad and make new students to feel at home in a foreign land. We also apply for scholarship to study at all  post secondary school level with summer internship space. You can use our <Link to="/contact-us">contact us</Link> page or visit our website at <a href="https://surewaysedu.com/" target="_blank">Sureways Edu</a></p>
                            </div> 
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>  
            </section>

            {/* <!--====================================================
                                CLIENT
            ======================================================--> */}
            <section id="client" className="client  testimonials-p2">
                <div className="container">
                    <div className="row title-bar">
                        <div className="col-md-12">
                            <h1 className="wow fadeInUp">Our Client Say</h1>
                            <div className="heading-border"></div>
                            <p className="wow fadeInUp" data-wow-delay="0.4s">We committed to helping you maintain your Brand Value.</p>
                        </div>
                    </div>
                    <div className="row">                     
                        {
                            [...props.testimonies].map((testimony,index) =>{
                                if(index < 4){
                                    return <EachTestimony key={index} testimony={testimony} />
                                }
                            }
                            )
                        }
                    </div>
                </div>        
            </section>
            
        </>
    )
}

import React from 'react'

export default function PageIntro(props) {
    const {design, title, intro} = props;
    return (
        <div id="home-p" className={`home-p ${design} text-center`}>
			<div className="container">
				<h1 className="wow fadeInUp" data-wow-delay="0.1s">{intro}</h1>
				<p>{title}</p>
			</div>
		</div> 
    )
}

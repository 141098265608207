import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import PageIntro from '../components/PageIntro';
import News from '../components/News';
import QuickContact from '../components/QuickContact';
import BussinessGrowth from '../components/BussinessGrowth';
import '../../assets/main/css/services.css'

class Services extends Component {
  render() {
    return (
      <> 
        <Header />
        <PageIntro intro="Businessbox is fully responsive and Clean" design="pages-head2" title="Our Services" />
        <BussinessGrowth />
        <QuickContact />
        <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
        <Footer />
      </>
    );
  }
}

export default Services;

import React from 'react'
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import PageIntro from '../components/PageIntro';

export default function Disclaimer() {
    return (
        <>
        <Header />
        <PageIntro intro="Disclaimer" design="pages-head1" title="This disclaimer of Myschoolgateway." />
            <section id="contact-p1" className="contact-p1"> 
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>Disclaimer</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    The information contained on https://www.myschoolgateway.com/ website (the “Service”) is for general information purposes only.

                                    Myschoolgateway assumes no responsibility for errors or omissions in the contents on the service.

                                    In no event shall Myschoolgateway be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service.
                                    Myschoolgateway reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.<br/>

                                    Myschoolgateway does not warrant that the website is free of viruses or other harmful components.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>External links disclaimer</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    https://www.myschoolgateway.com/ website may contain links to external websites that are not provided or maintained by or in any way affiliated with Myschoolgateway

                                    Please note that the Myschoolgateway does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
                                    <br/><span style={{color:'red'}}>This document was last updated on October 01, 2018</span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import parser from 'html-react-parser'
import PropTypes from 'prop-types'

import Header from '../../main/components/layout/Header';
import Footer from '../../main/components/layout/Footer';
import PageIntro from '../../main/components/PageIntro';
import '../../assets/main/css/news.css'
import SmallFeed from '../components/SmallFeed';
import Comments from '../components/Comments';
import { linkEncode,subscribe,formatViews, reduceArray, sortDesc, getAsideFeeds, getMeta, changeMeta } from '../../Funcs';
import { fetchFeedDetails,fetchFeeds,uploadComment } from '../../redux/actions/blogActions'

class FeedDetails extends Component {
    static propTypes = {
        feeds: PropTypes.array.isRequired,
        feed: PropTypes.object.isRequired,
    }

    state = {
        latestFeeds:[],
        popularFeeds: [],
        feed:{}
    }
    componentWillMount = () => {
        let {date_added} = this.props.match.params
        this.props.fetchFeedDetails(date_added)
        this.props.fetchFeeds()
    }
	componentDidMount= ()=>{
        // this.updateStateDef()
		setTimeout(this.updateStateDef,1000)
        const {image,total_comments,title,body,tags,category,admin,date,views,description,link} = this.props.feed
        
        //setting the open graphics properties
        // if(image != undefined){
        //     console.log("old: ",getMeta("og-image"));
        //     console.log("old: ",getMeta("og-description"));
        //     console.log("old: ",getMeta("og-url"));
        //     changeMeta("og-image",`https://surewaysedu.com/bucket/${image}`)
        //     changeMeta("og-description",description+"...")
        //     changeMeta("og-url",linkEncode(`https://surewaysedu.com${link}`) ) 
        //     console.log("new: ",getMeta("og-image"));
        //     console.log("new: ",getMeta("og-description"));
        //     console.log("new: ",getMeta("og-url"));
        // }
        // setTimeout(function(){    
        //     console.log("old: ",getMeta("og-image"));
        //     console.log("old: ",getMeta("og-description"));
        //     console.log("old: ",getMeta("og-url"));
        //     changeMeta("og-image",`https://surewaysedu.com/bucket/${image}`)
        //     changeMeta("og-description",description+"...")
        //     changeMeta("og-url",linkEncode(`https://surewaysedu.com${link}`) ) 
        //     console.log("new: ",getMeta("og-image"));
        //     console.log("new: ",getMeta("og-description"));
        //     console.log("new: ",getMeta("og-url"));
        // },5000)
    }
    
    updateStateDef = () =>{
        const { feeds,feed } =  this.props
        var category = ""
        var id = 0;
        if(this.props.feed.category != 'undefined'){
            category = this.props.feed.category
            id = this.props.feed.id
        }
        var newFeeds = [...feeds].filter(feed => feed.category == category)
        //remove the active feed from new feed so that user wont click on it again
        newFeeds = [...newFeeds].filter(feed => feed.id != id)
        const latestFeeds = getAsideFeeds(feeds,newFeeds,"id",7);
        const popularFeeds = getAsideFeeds(feeds,newFeeds,"views",7)
        this.setState((prevState,props) =>({
            feeds,
            feed,
            popularFeeds,
            latestFeeds,
        }))
        
    }
    //submit comment to database    
    submitComment = (name,email,message,replyID) => {
        const feed = this.props.feed;
        this.props.uploadComment(feed.id,feed.date_added,replyID,name,email,message)
    }

    render() {

        if(typeof this.props.feed.comments == 'undefined'){
            return <div />
        }
        const {toReply,popularFeeds,latestFeeds} = this.state
        const {image,total_comments,title,body,tags,category,admin,date,views,description,link,comments} = this.props.feed

        let commentCount = ""
        if(total_comments == 0){
            commentCount = "Nil"
        }
        else if(total_comments == 1){
            commentCount = "1 Comment"
        }
        else{
            commentCount = total_comments+" Comments";
        }
        
        // const {image,total_comments,title,body,tags,category,admin,date,views} = this.state.feed

        // tags = tags.split(',');
        const feedUrl = window.location.href
        //setting the open graphics properties
        // console.log("old image: ",getMeta("og-image"));
        // console.log("old description: ",getMeta("og-description"));
        // console.log("old url: ",getMeta("og-url"));
        changeMeta("og-image",`https://myschoolgateway.com/bucket/${image}`)
        changeMeta("og-description",description.substr(0,262)+"...".replace(/(<([^>]+)>)/ig,""))
        changeMeta("og-url",linkEncode(`https://myschoolgateway.com${link}`) ) 
        // console.log("new image: ",getMeta("og-image"));
        // console.log("new description: ",getMeta("og-description"));
        // console.log("new url: ",getMeta("og-url"));
        

        return (
            <>
                <Header />
                <PageIntro intro="News" design="pages-head1" title="Discover more" />
                <section id="single-news-p1" className="single-news-p1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">                                
                                <div className="single-news-p1-cont">
                                    <div className="single-news-img">
                                        <img src={`${process.env.PUBLIC_URL}/bucket/${image}`} alt="" className="img-fluid" style={{height:300}} />
                                    </div>
                                    <div className="single-news-desc">
                                        <h4>{title}</h4>
                                        <ul className="list-inline">
                                        <li>Posted: <span className="text-theme-colored2"> {date}</span></li>
                                        <li>By: <span className="text-theme-colored2">{admin}</span></li>
                                        <li><i className="fa fa-briefcase"></i>  <span className="text-theme-colored2">{category}</span></li>
                                        <li><i className="fa fa-comments-o"></i> {commentCount}</li>
                                        </ul>
                                        <hr/>
                                        <div className="bg-light-gray">
                                            {parser(body)}
                                        </div>
                                        <Comments submitComment={this.submitComment} comments={comments} />
                                    </div>
                                </div>  
                                <hr/>
                            </div>
                            <div className="col-md-4">
                                <div className="small-news-box">
                                    <div className="clearfix"></div>
                                    <h4 style={{"color":"#777","fontWeight":"bold"}} className="pb-2">Popular Feeds</h4>
                                    {
                                        popularFeeds.map((feed,index)=>(
                                            <SmallFeed feed={feed} key={index} />
                                        ))
                                    }
                                    <div className="clearfix"></div>
                                    <h4 style={{"color":"#777","fontWeight":"bold",margin:"10px"}} className="pb-2">Latest Feeds</h4>
                                    {
                                        latestFeeds.map((feed,index)=>(
                                            <SmallFeed feed={feed} key={index} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    feeds: state.blog.feeds,
    feed:state.blog.feedDetails
})

const mapDispatchToProps = {
    fetchFeedDetails,fetchFeeds,uploadComment
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedDetails)

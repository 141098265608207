export default {
    FETCH_SCHOOLS: 'FETCH_SCHOOLS',
    TOGGLE_SEARCH: 'TOGGLE_SEARCH',

    FETCH_EXPERTS: 'FETCH_EXPERTS',
    FETCH_SEARCH:'FETCH_SEARCH',
    FETCH_SEARCH_DETAILS:'FETCH_SEARCH_DETAILS',

    FETCH_SOCIALS:'FETCH_SOCIALS',

    MAIN_ONLINE:'https://myschoolgateway.com/lib/php/rest_api/api.main.php',
    MAIN_OFFLINE:'http://ohaneze/myschoolgateway.com/lib/php/rest_api/api.main.php',
}
import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import PageIntro from '../components/PageIntro';
import ContactBox from '../components/ContactBox';
import Map from '../components/Map';


import '../../assets/main/css/contact.css'
class Contact extends Component {
  render() {
    return (
        <>
            <Header />
            <PageIntro intro="Contact Us" design="pages-head4" title="Please feel free to reach us on any of these channels." />
            <ContactBox />
            <Map />
            <Footer />
        </>
    );
  }
}

export default Contact;

import React,{useState} from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css' 
import 'owl.carousel/dist/assets/owl.theme.default.css' 

export default function AffiliateTestimonies() {
    const settings = {
        loop:true,
        margin:10,
        items:1,
        autoplay:true,
        dots:false
    }
    const [affTestimonies, setAffTestimonies] = useState([
        {
            name:"Okechukwu Daniel",
            image:"sgw_male.png",
            work:"Affiliate Marketer",
            description:"God bless Myschoolgateway!!!!"
        },{
            name:"Joseph Kingsely",
            image:"sgw_female.png",
            work:"Affiliate Marketer",
            description:"This is the best affiliate program so far. I get my payment within 24 hours I requested for the payout"
        },{
            name:"Funke Fashola",
            image:"sgw_female.png",
            work:"Affiliate Marketer",
            description:"The sweetest thing with this affiliate program is that i joined when I have no cash at hand because there is no registration fee now i am earning like a boss"
        },{
            name:"Okereke Joshua Walter",
            image:"sgw_male.png",
            work:"Affiliate Marketer",
            description:"Myschoolgateway is an affiliate program you can trust. No regsitration fee, The services are loved by schools, it is cheaper though it affects our payment as affiliate marketers but it is easier to get schools through it even school that has been running a school portal already"
        },
    ])

    return (
        <section id="testimonials-p1" className="bg-parallax testimonials-p1-bg">
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-8 d-sm-none d-md-block">
                    <h3 className="text-white text-center" style={{fontWeight:"bolder",marginTop:250}}>Affiliate Testimonies</h3>
                </div>
                <div className="col-md-4 bg-gradiant testimonials-p1-pos">
                    <div className="testimonials-p1-cont">
                    <OwlCarousel className="text-center owl-theme" {...settings} >
                        {
                            affTestimonies.map((testify,index)=>(
                                <div className="testimonial" key={index}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/main/img/${testify.image}`} alt="testimonial" className="img-fluid rounded-circle" />
                                    <blockquote>
                                        <p>{testify.description}</p>
                                    </blockquote>
                                    <div className="testimonial-author">
                                        <p>
                                            <strong>{testify.name}</strong>
                                            <span>{testify.work}</span>
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                    </div>
                </div>
                </div>
            </div>
        </section> 
    )
}

import React, { Component } from 'react'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import News from '../components/News';
import Project from '../components/Project';
import PageIntro from '../components/PageIntro';
import '../../assets/main/css/project.css'

class Projects extends Component {
    render() {
        return (
            <>
                <Header />
                <PageIntro intro="Innovation Project" design="pages-head2" title="New Idea" />
                <Project />
                <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
                <Footer />
            </>
        )
    }
}

export default Projects

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { subscribe, linkEncode } from '../../../Funcs'
import { fetchLatestFeeds } from '../../../redux/actions/blogActions'
import { fetchSocials } from '../../../redux/actions/mainActions'

class Footer extends Component {
  static propTypes = {
    // socials: PropTypes.array.isRequired
    latest: PropTypes.array.isRequired
  }
  componentWillMount(){
    this.props.fetchLatestFeeds()
    // this.props.fetchSocials()
  }
  state = {
    email:"",
  }
   onChange = (e) =>{
     this.setState({
       [e.target.name]: e.target.value
     })
   }
   onSubmit = (e) =>{
     e.preventDefault();
     const email = this.state.email
     subscribe(email,"Welcome")
   }
  render() {
    const date = new Date(); 
    // console.log(this.props.latest)
    // const {facebook,youtube,twitter,instagram} = this.props.socials
    return (
		  <footer> 
        <div id="footer-s1" className="footer-s1">
          <div className="footer">
            <div className="container">
              <div className="row">
                {/* <!-- About Us --> */}
                <div className="col-md-3 col-sm-6 ">
                  <div><img src="img/logo-w.png" alt="" className="img-fluid" /></div>
                  <ul className="list-unstyled comp-desc-f">
                     <li>
                       <p>
                         Myschoolgateway has been around since 2018, helping the schools and teachers to carry out some of the administrative activites thereby easing stress in many ways.
                        </p>
                      </li> 
                  </ul><br/> 
                </div>
                {/* <!-- End About Us --> */}

                {/* <!-- Recent News --> */}
                <div className="col-md-3 col-sm-6 ">
                  <div className="heading-footer"><h2>Useful Links</h2></div>
                  <ul className="list-unstyled link-list">
                    <li><Link to="/about-us">About us</Link> </li> 
                    <li><Link to="/affiliates">Affiliate Marketing</Link> </li> 
                    <li><Link to="/testimonials">Testimonies</Link> </li> 
                    <li><Link to="/FAQs">FAQ</Link></li> 
                    <li><Link to="/contact-us">Contact us</Link></li> 
                  </ul>
                </div>
                {/* <!-- End Recent list --> <Link to="/"></Link> */}

                {/* <!-- Recent Blog Entries --> */}
                <div className="col-md-3 col-sm-6 ">
                  <div className="heading-footer"><h2>Recent Post Entries</h2></div>
                  <ul className="list-unstyled thumb-list">
                  {
                    [...this.props.latest].map(feed=>(
                      <li key={feed.id}>
                        <div className="overflow-h">
                          <Link to={linkEncode(feed.link)}>{feed.title}</Link>
                          <small>{feed.date}</small>
                        </div>
                      </li>
                    ))
                  }
                  </ul>
                </div>
                {/* <!-- End Recent Blog Entries --> */}

                {/* <!-- Latest Tweets --> */}
                <div className="col-md-3 col-sm-6">
                  <div className="heading-footer"><h2>Get In Touch</h2></div>
                  <address className="address-details-f">
                    Opp. Total filling station <br/>
                    Awka-etiti Ekwulobia Road, Awka-etiti. <br/>
                    Idemili South, Anambra State, Nigeria. <br/>
                    Phone: +234 903 9146 415<br/>                        
                    Email: <br/>
                    <a href="mailto:myschoolgatewaywebmaster@gmail.com" className="">info@myschoolgateway.com</a><br/>                        
                    <a href="mailto:myschoolgatewaywebmaster@gmail.com" className="">support@myschoolgateway.com</a>
                  </address>  
                  <ul className="list-inline social-icon-f top-data">                  
                    <li><a href="https://www.facebook.com/mysgw" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
                    <li><a href="https://www.twitter.com/myschoolgateway" target="_empty"><i className="fa top-social fa-twitter"></i></a></li>
                    <li><a href="https://www.Instagram.com/myschoolgateway" target="_empty"><i className="fa top-social fa-instagram"></i></a></li> 
                    <li><a href="https://www.wa.me/2349039146415" target="_empty"><i className="fa top-social fa-whatsapp" ></i></a></li>
                  </ul>
                </div>
                {/* <!-- End Latest Tweets --> */}
              </div>
             </div>{/*<!--/container --> */}
          </div> 
        </div>

        <div id="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="footer-copyrights">
                            <p>
                              All Rights Reserved by OCAEN softwares.© 2018 OCAEN softwares. <Link to="/disclaimer">Disclaimer</Link> | <Link to="/privacy-policy">Privacy Policy</Link> <Link to="/terms-and-condition">Terms of Services</Link>
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <a href="#home-p" id="back-to-top" className="btn btn-sm btn-green btn-back-to-top smooth-scrolls hidden-sm hidden-xs" title="home" role="button">
            <i className="fa fa-angle-up"></i>
        </a>
      </footer>
    )
  }
}

const mapStateToProps = (state) => ({
  // socials:state.main.socials,
  latest:state.blog.latestFeeds
})

const mapDispatchToProps = {
  // fetchSocials,
  fetchLatestFeeds
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

import React, { Component } from 'react'

class Members extends Component {
    render() {
        return (
            <>
                
            {/* <!--====================================================
                                TEAM-P1 
            ======================================================--> */}
                <section id="team-p1" className="team-p1">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="team-p1-cont wow fadeInUp" data-wow-delay="0.3s">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/team/t-2.jpg`} className="img-fluid" alt="..."/>
                        <h5>Lena boss</h5>
                        <h6>CEO & Cofounder</h6>
                        <ul className="list-inline social-icon-f top-data">
                            <li><a href="#" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
                            <li><a href="#" target="_empty"><i className="fa top-social fa-google-plus"></i></a></li> 
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="team-p1-cont wow fadeInUp" data-wow-delay="0.6s">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/team/t-5.jpg`} className="img-fluid" alt="..."/>
                        <h5>Rohn Soj</h5>
                        <h6>Manager &amp; Cofounder</h6>
                        <ul className="list-inline social-icon-f top-data">
                            <li><a href="#" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
                            <li><a href="#" target="_empty"><i className="fa top-social fa-google-plus"></i></a></li> 
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>      
                </section> 
            
                {/* <!--====================================================
                                    TEAM-P2 
                ======================================================--> */}
                <section id="comp-offer" className="team-p2">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 col-sm-6  desc-comp-offer">
                        <h2>Team Member</h2>
                        <div className="heading-border-light"></div> 
                    </div>
                    </div>  
                    <div className="row">  
                    <div className="col-md-4 col-sm-6 ">
                        <div className="team-p2-cont wow fadeInUp" data-wow-delay="0.2s">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/team/t-1.jpg`} className="img-fluid" alt="..."/>
                        <h5>Seena zell</h5>
                        <h6>CEO & Cofounder</h6>
                        <ul className="list-inline social-icon-f top-data">
                            <li><a href="#" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
                            <li><a href="#" target="_empty"><i className="fa top-social fa-google-plus"></i></a></li> 
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 ">
                        <div className="team-p2-cont wow fadeInUp" data-wow-delay="0.4s">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/team/t-3.jpg`} className="img-fluid" alt="..."/>
                        <h5>Tom Gate</h5>
                        <h6>CEO & Cofounder</h6>
                        <ul className="list-inline social-icon-f top-data">
                            <li><a href="#" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
                            <li><a href="#" target="_empty"><i className="fa top-social fa-google-plus"></i></a></li> 
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 ">
                        <div className="team-p2-cont wow fadeInUp" data-wow-delay="0.6s">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/team/t-4.jpg`} className="img-fluid" alt="..."/>
                        <h5>Jesia Ben</h5>
                        <h6>CEO & Cofounder</h6>
                        <ul className="list-inline social-icon-f top-data">
                            <li><a href="#" target="_empty"><i className="fa top-social fa-facebook"></i></a></li>
                            <li><a href="#" target="_empty"><i className="fa top-social fa-google-plus"></i></a></li> 
                            </ul>
                        </div>
                    </div> 
                    </div>
                </div>
                </section>

            </>
        )
    }
}

export default Members

import React from 'react'

export default function AboutUs() {
    return (
        <>
           
        {/* <!--====================================================
                                ABOUT-P1
        ======================================================--> */}
            <section id="about-p1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="about-p1-cont">
                                <h1>About us </h1>
                                {/* <p>
                                    Our Business operates in more than two countries across five continents, with a mission 'To improve the quality of life of the communities we serve globally, through long-term stakeholder value creation based on Leadership with Trust' typesetting, remaining essentially unchanged.
                                </p> */}
                                <p> 
                                    MySchoolgateway is a universal school system based in Nigeria, offering online services and creating a secured school portal nationwide. 
                                </p>
                                <p>  
                                    Among the offerings of the company’s services are career guidance, creating personal websites for schools, creating of students and staffs online profile, calculating the results of the students, printing the result in any part of the world, checking on student ability and improvement over the years, giving comments on the improvements, checking on the strength of a subject teacher and comparing it with previous teachers, tracking the improvement in the school growth, population and education wise. We also provide chance for the printing of the teacher result cass sheet.
                                    MySchoolgateway also offers motivational and valedatory speech to students especially graduating classes if we are given the opportunity to come and speak.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-p1-img">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/image-5.jpg`} className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        {/* <!--====================================================
                            ABOUT-P2
        ======================================================--> */}
            <section className="about-p2 bg-gradiant">
                <div className="container-fluid">
                    <div className="row">
                        <div className="about-p2-heading">
                            <h1 className="cl-white">global leader in school portal services</h1>
                            <div className="heading-border-light bg-white"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="about-p2-cont cl-white">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/image-6.jpg`} className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="..." />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-p2-cont cl-white wow fadeInUp" data-wow-delay="0.3s">
                                <h5>Mission</h5>
                                <p className="cl-white">
                                We believe everyone is smart and this is reflected in everything that makes up our brand. Our service delivery is specially designed for smart schools and our objective to make more schools smart. It is, therefore, our mission to create an efficient system that makes work stress free and enables the quests for internet by providing the right technology, radical innovation and uncompromised service to all our customers.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-p2-cont cl-white wow fadeInUp" data-wow-delay="0.5s">
                                <h5>Vision</h5>
                                <p className="cl-white">
                                To create an ecosystem where doing some selectively school activities become stress free for teachers and schools, and given the students the best we can by correctly calculating their results and giving them career guidance and counselling.
                                To develop and radically expand the effective use of computers and internet among teenagers in Africa.
                                To make education smarter in Africa
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====================================================
                                ABOUT-P3
            ======================================================--> */}
            <section id="story" className="about-p3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="story-desc">
                                <h3>Our Values</h3>
                                <div className="heading-border-light"></div>
                                <p>
                                    Being the best is a driver for us at MySchoolgateway, which is why we put a lot of effort into crafting, maintaining and upgrading all aspects of our service to make sure that they suit the requests of our customers and match or even exceed their expectations. In delivering this, we also believe in the following; Education is life
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="story-himg">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/img/image-3.jpg`} className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-6 ">
                            <div className="story-himg story-himg-middle-u">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/img/image-2.jpg`}  className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="story-desc story-desc-middle ">
                                <h3>Social Responsibility</h3>
                                <div className="heading-border-light"></div>
                                <p>
                                    Myschoolgateway is dedicated to changing the face of online school system in Africa and in doing so, we are committed to initiatives that improve the conditions of the school we offer services to. One of our initiatives is to enroll over 1000 schools in our universal school system by offering African schools opportunities to enroll for free.
                                    Click <a href="https://www.myschoolgateway.com/registration.php">here</a> to join the smart schools!
                                 </p>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="story-himg story-himg-middle-l">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/img/image-2.jpg`}  className="img-fluid" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="story-desc">
                                <h3>Our customers are Smart</h3>
                                <div className="heading-border-light"></div>
                                <p>
                                    All our customers deserve premium service.
                                    Trust is important to us which is why we choose to be transparent in our engagement with our customers and the public. 
                                    Hard work & Innovation, the perfect combination
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="story-himg">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/img/image-1.jpg`}  className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}

import React from 'react'

export default function BussinessGrowth() {
    return (
        <>            
        <section id="business-growth-p1" className="business-growth-p1 bg-gray">
            <div className="container">
                <div className="row title-bar">
                <div className="col-md-12">
                    <h1 className="wow fadeInUp">We committed to helping</h1>
                    <div className="heading-border"></div>
                    <p className="wow fadeInUp" data-wow-delay="0.4s">We committed to helping you maintain your oral healthTooth.We are an innovative company. We develop and design websites for costumers around the world. Our clients are some of the most forward-looking companies in the world.</p>
                </div>
                </div>
                <div className="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="col-md-3 col-sm-6 service-padding">
                    <div className="service-item">
                        <div className="service-item-icon"> <i className="fa fa-paint-brush fa-3x"></i>
                        </div>
                        <div className="service-item-title">
                            <h3>Web Design</h3>
                        </div>
                        <div className="service-item-desc">
                            <p>Laborum adipisicing do amet commodo occaecat do amet commodo occaecat.</p> 
                            <div className="content-title-underline-light"></div> 
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 service-padding">
                    <div className="service-item">
                        <div className="service-item-icon"> <i className="fa fa-laptop fa-3x"></i>
                        </div>
                        <div className="service-item-title">
                            <h3>Web Devlopment</h3>
                        </div>
                        <div className="service-item-desc">
                            <p>Laborum adipisicing do amet commodo occaecat do amet commodo occaecat.</p>
                            <div className="content-title-underline-light"></div> 
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 service-padding">
                    <div className="service-item">
                        <div className="service-item-icon"> <i className="fa fa-table fa-3x"></i>
                        </div>
                        <div className="service-item-title">
                            <h3>Mobile Apps</h3>
                        </div>
                        <div className="service-item-desc">
                            <p>Laborum adipisicing do amet commodo occaecat do amet commodo occaecat.</p>
                            <div className="content-title-underline-light"></div> 
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 service-padding">
                    <div className="service-item right-bord">
                        <div className="service-item-icon"> <i className="fa fa-search fa-3x"></i>
                        </div>
                        <div className="service-item-title">
                            <h3>SEO Optimization</h3>
                        </div>
                        <div className="service-item-desc">
                            <p>Laborum adipisicing do amet commodo occaecat do amet commodo occaecat.</p>
                            <div className="content-title-underline-light"></div> 
                        </div>
                    </div>
                </div> 
                </div>
            </div>  
        </section> 
        {/* <!--====================================================
                            FINANCIAL-P1
        ======================================================-->      */}
            <section id="financial-p1" className="bg-parallax financial-p3-bg">
                <div className="overlay-financial-p1"></div>
                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 financial-p1-pos">
                    <div className="financial-p1-cont">    
                        <a href="#"> <b>Richtech Technology</b></a>  
                        <h3>Excellent Strategies For </h3>
                        <p>Businessbox will deliver value to all the stakeholders and will attain excellence and leadership through such delivery of value. We will strive to support the stakeholders in all activities related to us. Businessbox provide great things.Everyone defines success differently – as much as there are people, there are different opinions. Number one in our priority list is the success of our students, alumni and their employers.</p>     
                        <button className="btn btn-general btn-white" role="button">Contact Us</button>
                    </div>
                    </div>
                    <div className="col-md-6 financial-p1-emp-img"></div>
                </div>
                </div>
            </section> 
            
            <section id="financial-p1" className="bg-parallax financial-p2-bg">
                <div className="overlay-financial-p1"></div>
                <div className="container-fluid">
                <div className="row"> 
                    <div className="col-md-6 financial-p1-emp-img"></div>
                    <div className="col-md-6 financial-p1-pos financial-p1-emp-desc-d">
                    <div className="financial-p1-cont">    
                        <a href="#"> <b>Richtech Technology</b></a>  
                        <h3>Transfer Financial Services</h3>
                        <p>Businessbox will deliver value to all the stakeholders and will attain excellence and leadership through such delivery of value. We will strive to support the stakeholders in all activities related to us. Businessbox provide great things.Everyone defines success differently – as much as there are people, there are different opinions. Number one in our priority list is the success of our students, alumni and their employers.</p>     
                        <button className="btn btn-general btn-white" role="button">Contact Us</button>
                    </div>
                    </div>
                </div>
                </div>
            </section>  

            {/* <!--====================================================
                                FINANCIAL-P5
            ======================================================-->     */}
            <section className="what-we-do bg-gradiant financial-p5">
            <div className="container">
                <div className="row title-bar">
                <div className="col-md-12">
                    <h1 className="wow fadeInUp cl-white">Global Business Services</h1>
                    <div className="heading-border bg-white"></div> 
                </div>
                </div>
                <div className="row"> 
                <div className="col-md-12">
                    <div className="row">
                    <div className="col-md-4  col-sm-6">
                        <div className="what-we-desc wow fadeInUp" data-wow-delay="0.1s">
                        <i className="fa fa-bar-chart"></i>
                        <h6>Professional Growth</h6>
                        <p className="desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        </div>
                    </div>
                    <div className="col-md-4  col-sm-6">
                        <div className="what-we-desc wow fadeInUp" data-wow-delay="0.1s">
                        <i className="fa fa-bullseye"></i>
                        <h6>Research &amp; Development </h6>
                        <p className="desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        </div>
                    </div>
                    <div className="col-md-4  col-sm-6">
                        <div className="what-we-desc wow fadeInUp" data-wow-delay="0.1s">
                        <i className="fa fa-star-o"></i>
                        <h6>Valuable Services</h6>
                        <p className="desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        </div>
                    </div> 
                    </div>
                </div>
                </div>
            </div>      
            </section> 

            {/* <!--====================================================
                                FINANCIAL-P6
            ======================================================--> */}
            <section id="financial-p6" className="financial-p6"> 
                <div className="container">
                <div className="row">
                    <div className="col-md-6">
                    <div className="financial-p6-img">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/image-3.jpg`} className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="..."/>
                        <img src="" alt=""/>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="financial-p6-cont">
                        <h2 className="wow fadeInUp">Creative Idea Businessbox </h2>
                        <div className="heading-border-light"></div>
                        <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <button className="btn btn-general btn-white" role="button">Contact</button>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

import c from './main.types'
import { setCookie } from '../../Funcs';
const link = c.MAIN_ONLINE

// send message
export const sendFeedBack = (fullname,email,mobile,subject,message,page) =>{
    fetch(`${link}?send_message&fullname=${fullname}&email=${email}&mobile=${mobile}&subject=${subject}&message=${message}&page=${page}`)
    .then(res => res.json())
    .then(msg => {
        if(msg == 1){
            alert("Message sent successfully, we will get back to you through email or phone call")
        }
        else{
            alert("Message not sent, Please check your details and try again")
        }
    })

}

//fetch all schools
export const fetchSchools = () => dispatch =>{
    fetch(`${link}?fetch_schools`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_SCHOOLS,
        payload:data
    }))
}

//fetch all schools
export const toggleSearch = (bool) => dispatch =>{
    dispatch({
        type: c.TOGGLE_SEARCH,
        payload:bool
    })
}

// record visitor
export const recordVisit = (page) =>{
    fetch(`${link}?record_visitor&page=${page}`)
    .then(res => res.json())
    .then(msg => {
        if(msg == 1){
            console.log("visitor recorded successfully");
        }
        else{
            console.log("failed to record visitor");
        }
    })
}


// set sgw session
export const setSGWSession = (sgwid) =>{
    setCookie("mysgwid",sgwid,1); 
    // fetch(`${link}?set_session&sgwid=${sgwid}`)
    // .then(res => res.json())
    // .then(msg => {
    //     if(msg == 1){
    //         window.location = "https://myschoolgateway.com/myschool/";
    //         // window.location = "http://ohaneze/myschoolgateway.com/myschool/";
    //         // console.log("session set successfully",msg);
    //     }
    //     else{
    //         console.log("failed to set session: ",msg);
    //     }
    // })
}
// set sgw session
export const setSchSession = (type,val1,val2) =>{
    fetch(`${link}?set_sch_session&type=${type}&val1=${val1}&val2=${val2}`)
    .then(res => res.json())
    .then(msg => {
         if(msg.includes("sgw00")){
            // console.log("MYSGW SET SUCCESSFULLY: ",msg);
            setCookie("mysgwid",msg,1);
            window.location = "https://myschoolgateway.com/myschool/";
        }
        else{
            console.log("failed to set session: ",msg);
        }

        // if(msg == 1){
        //     window.location = "https://myschoolgateway.com/myschool/";
        //     // window.location = "http://ohaneze/myschoolgateway.com/myschool/";
        // }
        // else{
        //     console.log("failed to set session: ",msg);
        // }
    })
}





//fetch experts for about us page
export const fetchExperts = () => dispatch =>{
    fetch(`${link}?fetch_experts`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_EXPERTS,
        payload:data
    }))
}

//fetch similar result of search list
export const fetchSearch = (keyword) => dispatch =>{
    fetch(`${link}?fetch_search&keyword=${keyword}`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_SEARCH,
        payload:data
    }))
}
//fetch similar result of search list
export const fetchFullSearch = (keyword) => dispatch =>{
    fetch(`${link}?fetch_full_search&keyword=${keyword}`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_SEARCH_DETAILS,
        payload:data
    }))
}


//fetch social network links
export const fetchSocials = () => dispatch =>{
    fetch(`${link}?fetch_socials`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_SOCIALS,
        payload:data
    }))
}

// //send feedback
// export const sendFeedBack = (name,email,mobile,subject,message) => {
//     return fetch(`${link}?send_feedback&name=${name}&email=${email}&mobile=${mobile}&subject=${subject}&message=${message}`)
//     .then(res => res.json())
//     .then(msg => {
//         return msg
//     })
// }
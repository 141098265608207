import React from 'react'

export default function Map() {
    return (
        <>
            <section id="contact-add">
                <div id="map">
                    <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d6030.418742494061!2d-111.34563870463673!3d26.01036670629853!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1471908546569" title="Myschoolgateway Head Office" width="600" height="450" frameborder="0" style={{"border":0}} allowfullscreen></iframe>
                    </div>
                </div> 
            </section> 
        </>
    )
}

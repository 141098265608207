import React from 'react'
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import PageIntro from '../components/PageIntro';

export default function Terms() {
    return (
        <>
        <Header />
        <PageIntro intro="Agreement" design="pages-head1" title="Terms and Conditions." />
            <section id="contact-p1" className="contact-p1"> 
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>1. Introduction.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                These Website Standard Terms And Conditions contained herein on this webpage, shall govern your use of this website, including all pages within this website.                        
                                These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>2. Intellectual Property Rights.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    We cannot be hold responsible for any issues that happens in your school account, such as illegal login to the staff/student portal.
                                    We only login to accounts that we are authorised to. Staffs are advised to protect their login details to avoid phising of any type.
                                    We provided maximum security for the schools. We cannot be held responsible for the lost of data of any form, schools are advised to backup their data to their computer at the end of each session.

                                    {/*Other than content you own, which you may have opted to include on this Website, under these Terms, My school gateway and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved.

                                    You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website,

                                    PandaTip: If the website is only for viewing, then you might be able to stop where we left off above. That said, you will likely need to provide more in the way of descriptive language around what users may use the website to do/for. */}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>3. Restrictions.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    You are expressly and emphatically restricted from all of the following:<br/>

                                    {/* publishing any Website material in any media;<br/> */}

                                    selling, sublicensing and/or otherwise commercializing any Website material;<br/>

                                    {/* publicly performing and/or showing any Website material;<br/> */}

                                    1. using this Website in any way that is, or may be, damaging to this Website;<br/>

                                    2. using this Website in any way that impacts user access to this Website;<br/>

                                    3. using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or school;<br/>

                                    4. engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;<br/>

                                    5.using this Website to engage in any unauthorized advertising or marketing;<br/>

                                    Certain areas of this Website are restricted from access by you and Myschoolgateway may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion.  Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>4. Your Content.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Myschoolgateway a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.

                                Your Content must be your own and must not be infringing on any third party’s rights. Myschoolgateway reserves the right to remove any of Your unauthorized Content from this Website at any time, and for any reason, without notice.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>5. No warranties. </h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    This Website is provided “as is,” with all faults, and Myschoolgateway makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. 
                                    {/* Additionally, nothing contained on this Website shall be construed as providing consult or advice to you. */}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>6. Limitation of liability.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                In no event shall Myschoolgateway, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Myschoolgateway, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>7. Indemnification.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                You hereby indemnify to the fullest extent Myschoolgateway from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>8. Severability.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>9. Variation of Terms.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                Myschoolgateway is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>10. Assignment.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                Myschoolgateway shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, .you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>11. Entire Agreement.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Myschoolgateway and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>12. Governing Law & Jurisdiction.</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                These Terms will be governed by and construed in accordance with the laws of the State of [STATE], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [STATE] for the resolution of any disputes.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3>Agreement- Terms and Conditions</h3>
                                <div className="heading-border-light"></div> 
                                <p>
                                    We can not be hold responsible for any issues that happens in your school account, such as illegal login to the staff/student portal.
                                    We only login to accounts that we are authorised to. Staffs are advised to protect their login details to avoid phising of any type.
                                    We provided maximum security for the schools. We cannot be held responsible for the lost of data of any form, Schools are advised to backup their data to their computer at the end of each session.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3></h3>
                                <div className="heading-border-light"></div> 
                                <p>

                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact-p1-cont">
                                <h3></h3>
                                <div className="heading-border-light"></div> 
                                <p>

                                </p>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

import React from 'react'
import { Link } from 'react-router-dom';
import '../../assets/main/css/404.css'

export default function Error404() {
    return (
        <>
            <section className="hero-area">
                <div className="error-overlay"></div>
                <div className="container">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="block text-center">
                            <h1 className="animated wow fadeInLeft" data-wow-delay="0.3s" data-wow-duration=".2s">404!</h1>
                            <p className="animated wow fadeInRight" data-wow-delay="0.5s" data-wow-duration=".5s">Oops! something wrong.</p>
                            <div className="animated wow fadeInUp text-center" data-wow-delay="0.7s" data-wow-duration=".7s">
                            <span>
                                <Link className="btn btn-general btn-blue" to="/" role="button">Go to Home</Link> 
                            </span>
                            </div>
                        </div>
                    </div>
                </div>  
                </div>
            </section>
        </>
    )
}

import React, { Component } from 'react';

class Project extends Component {
  render() {
    return (
        <>
            <section id="project-p1" className="project-p1">
                <div className="container-fluid">
                    <div className="  row">
                        <div className="col-md-3 col-sm-6 col-xs-12 bg-matisse">
                        <div className="project-p1-cont wow fadeInUp  text-center" data-wow-delay="0.3s">
                            <i className="fa fa-line-chart fa-2x"></i>
                            <p>It is a long established fact</p>
                        </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 bg-chathams">
                        <div className="project-p1-cont wow fadeInUp  text-center" data-wow-delay="0.6s">
                            <i className="fa fa-leaf fa-2x"></i>
                            <p>It is a long established fact</p>
                        </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 bg-atlis">
                        <div className="project-p1-cont wow fadeInUp  text-center" data-wow-delay="0.9s">
                            <i className="fa fa-soccer-ball-o fa-2x"></i>
                            <p>It is a long established fact</p>
                        </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 bg-starship">
                        <div className="project-p1-cont wow fadeInUp  text-center" data-wow-delay="1.2s">
                            <i className="fa fa-ticket fa-2x"></i>
                            <p>It is a long established fact</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="project-p2" className="project-p2" style={{"background": "#a4cff1"}}>
			<div className="container-fluid">
				<div className="row"> 
					<div className="col-md-6">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc wow fadeInUp" data-wow-delay="0.3s">
							<h4>A Scalable Platform for new business High Performance solution </h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-4.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-6">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc wow fadeInUp" data-wow-delay="0.6s">
							<h4>Automatic blade swinging ceiling fan for easy cleaning with new technology</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-7.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-12">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc wow fadeInUp" data-wow-delay="0.3s">
							<h4>Automation Step-lock system in bus control with iphone and android app</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-3.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-6">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc">
							<h4>Light/ sound alert that informs user when the landline phone becomes dead</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-1.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-6">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc">
							<h4>Reminding the owner if keys are forgotten in the vehicle with application</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-5.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-6">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc">
							<h4>Water purifier in rural area</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-6.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-6">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc">
							<h4>Sensor to correct body posture /regulate viewing distance</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-2.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
					<div className="col-md-12">
					<div className="project-p2-cont">
						<div className="col-md-5 col-sm-6">
						<div className="project-p2-desc">
							<h4>Retractable spikes before the zebra crossing that come out when the traffic</h4>
							<a href="#" className="text-right"><i className="fa fa-arrow-circle-o-right"></i> See More</a>
						</div>
						</div>
						<img src={`${process.env.PUBLIC_URL}/assets/main/img/project/pro-8.jpg`} className="img-fluid" alt="..." />
					</div>
					</div>
				</div> 
			</div>
		</section>
        </>
    );
  }
}

export default Project;

import React from 'react'

export default function Features() {
    return (
        <div className="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
            <div className="col-md-3 col-sm-6 service-padding">
                <div className="service-item">
                    <div className="service-item-icon"> <i className="fa fa-laptop fa-3x"></i>
                    </div>
                    <div className="service-item-title">
                        <h3>Online Portal</h3>
                    </div>
                    <div className="service-item-desc">
                        <p>We provide a secured online portal for over 17 secondary schools</p> 
                        <div className="content-title-underline-light"></div> 
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 service-padding">
                <div className="service-item">
                    <div className="service-item-icon"> <i className="fa fa-rocket fa-3x"></i>
                    </div>
                    <div className="service-item-title">
                        <h3>Private website</h3>
                    </div>
                    <div className="service-item-desc">
                        <p>Schools can get their personal .com website so that the users will not go through myschoolgateway website to login to their account.</p>
                        <div className="content-title-underline-light"></div> 
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 service-padding">
                <div className="service-item">
                    <div className="service-item-icon"> <i className="fa fa-mobile fa-3x"></i>
                    </div>
                    <div className="service-item-title">
                        <h3>Mobile Application</h3>
                    </div>
                    <div className="service-item-desc">
                        <p>Myschoolgateway has a free offline mobile application for fast entering of records/student scores</p>
                        <div className="content-title-underline-light"></div> 
                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 service-padding">
                <div className="service-item right-bord">
                    <div className="service-item-icon"> <i className="fa fa-bar-chart fa-3x"></i>
                    </div>
                    <div className="service-item-title">
                        <h3>Performance Statistics</h3>
                    </div>
                    <div className="service-item-desc">
                        <p>We show clear statistics on improvement of teachers and students over an academic term</p>
                        <div className="content-title-underline-light"></div> 
                    </div>
                </div>
            </div> 
        </div>
    )
}

import c from '../actions/blog.types'

const initialState = {
    feeds: [],
    feedDetails: {},
    popularFeeds:[],
    latestFeeds:[],
    tags:[],
    categories:[],
    hotCategories:[],
    admin:{},

}
export default function(state = initialState, action){
    switch(action.type){
        case c.FETCH_FEEDS:
            return { 
                ...state,
                feeds: action.payload
            }
        case c.FETCH_POPULAR_FEEDS:
            return {
                ...state,
                popularFeeds: action.payload
            }
        case c.FETCH_LATEST_FEEDS:
            return {
                ...state,
                latestFeeds: action.payload
            }
        case c.FETCH_FEED_DETAILS:
            return {
                ...state,
                feedDetails: action.payload
            }
        case c.FETCH_TAGS:
            return {
                ...state,
                tags: action.payload
            }
        case c.FETCH_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case c.FETCH_HOT_CATEGORIES:
            return {
                ...state,
                hotCategories: action.payload
            }
        case c.FETCH_ADMIN:
            return {
                ...state,
                admin: action.payload
            }
        default:
            return state
    }
}
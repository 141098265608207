import c from './blog.types'
const link = c.MAIN_ONLINE

//fetch all feeds
export const fetchFeeds = () => dispatch =>{
    fetch(`${link}?fetch_feeds`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_FEEDS,
        payload:data
    }))
}
//fetch all feeds
export const fetchFeedDetails = (id) => dispatch =>{
    fetch(`${link}?fetch_feed_details&id=${id}`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_FEED_DETAILS,
        payload:data
    }))
}

//send a comment or reply 
export const uploadComment = (fid,f_date,cid,name,email,message) => dispatch =>{
    fetch(`${link}?upload_comment&fid=${fid}&cid=${cid}&name=${name}&f_date=${f_date}&email=${email}&message=${message}`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_FEED_DETAILS,
        payload:data
    }))
}
//fetch 3 most recent feeds
export const fetchLatestFeeds = () => dispatch =>{
    fetch(`${link}?fetch_latest_feeds`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_LATEST_FEEDS,
        payload:data
    }))
}

//fetch 8 most popular feeds
export const fetchPopularFeeds = () => dispatch =>{
    fetch(`${link}?fetch_popular_feeds`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_POPULAR_FEEDS,
        payload:data
    }))
}

//fetch 3 hot categories
export const fetchHotCategories = () => dispatch =>{
    fetch(`${link}?fetch_hot_categories`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_HOT_CATEGORIES,
        payload:data
    }))
}
//fetch all categories
export const fetchCategories = () => dispatch =>{
    fetch(`${link}?fetch_categories`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_CATEGORIES,
        payload:data
    }))
}
//fetch all tags
export const fetchTags = () => dispatch =>{
    fetch(`${link}?fetch_tags`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_TAGS,
        payload:data
    }))
}

//fetch an admin
export const fetchAdmin = (id) => dispatch =>{
    fetch(`${link}?fetch_admin&id=${id}`)
    .then(res => res.json())
    .then(data => dispatch({
        type: c.FETCH_ADMIN,
        payload:data
    }))
}

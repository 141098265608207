import React, { Component } from 'react'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import News from '../components/News';
import PageIntro from '../components/PageIntro';
import '../../assets/main/css/team.css'
import Members from '../components/Members';

class Team extends Component {
    render() {
        return (
            <>
                <Header />
                <PageIntro intro="We offer a huge opportunity for growth" design="pages-head1" title="Discover more about Team" />
                <Members />
                <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
                <Footer />
            </>
        )
    }
}

export default Team

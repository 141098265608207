import React from 'react'
import { Link } from 'react-router-dom';

export default function OurStory() {
    return (
        <>
        {/* <!--====================================================
                            WHAT WE DO
        ======================================================--> */}
        <section className="what-we-do bg-gradiant">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">                        
                        <h3>What we Do</h3>
                        <div className="heading-border-light"></div> 
                        <p className="desc">These are the benefits our clients get from us </p>
                    </div>
                    
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-line-chart"></i>
                                <h6>School progress</h6>
                                <p className="desc">Myschoolgateway helps the school to track and monitor their progress in population, etc over an academic year.</p>
                                {/* We also give the head teacher and school administrators statistics of each student based on his/her improvement in academics etc. */}
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-pie-chart"></i>
                                <h6>Student progress</h6>
                                <p className="desc">It helps the students to monitor his/her improvements in a subject or in total academics, it also aids in bringing the student closer to good use of computers and internet.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-bar-chart"></i>
                                <h6>Teacher Performance</h6>
                                <p className="desc">Myschoolgateway helps the school to monitor the perfomance of subject teachers, how well teach and how students understand them.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-comments"></i>
                                <h6>Communication</h6>
                                <p className="desc">We value the importance of communication. Each student have a chat system which they can use to interact with staffs and fellow students.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-globe"></i>
                                <h6>Universal</h6>
                                <p className="desc">Myschoolgateway is universal, we have gain the trust of many schools and we never disappoint our clients.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-shield"></i>
                                <h6>Secured</h6>
                                <p className="desc">Your security is one of our biggest concern. With us you cannot be a victim of any form of data breaching. We also have a fast and secured online payment gateways.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-rocket"></i>
                                <h6>Share Result</h6>
                                <p className="desc">The students can give out their result ID to anybody so that they can view the result and print it any where around the world giving students ability to send their results to their parents.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-bell-o"></i>
                                <h6>Notification</h6>
                                <p className="desc">Myschoolgateway send out SMS notification to all the students once their result has been published by the school.</p>
                                </div>
                            </div>
                            <div className="col-md-4  col-sm-6">
                                <div className="what-we-desc">
                                <i className="fa fa-calculator"></i>
                                <h6>Accurate</h6>
                                <p className="desc">It helps the teacher to calculate the results of all the students in just few hours and ensures the calculation of all result with no errors</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>           
        </section> 

        {/* <!--====================================================
                            STORY
        ======================================================-->  */}
        <section id="story" style={{"marginBottom":"-150px"}}>
            <div className="container">
            <div className="row title-bar">
                <div className="col-md-12">
                <h1 className="wow fadeInUp">Our Success Tranformation Story</h1>
                <div className="heading-border"></div> 
                </div>
            </div>
            </div>  
            <div className="container-fluid">
            <div className="row" >
                <div className="col-md-6" >
                <div className="story-himg" >
                    <img src={`${process.env.PUBLIC_URL}/assets/main/img/image-4.jpg`} className="img-fluid" alt="" />
                </div>
                </div>
                <div className="col-md-6">
                <div className="story-desc">
                    <h3>What we are up to</h3>
                    <div className="heading-border-light"></div>
                    {/* <p>
                        MySchoolgateway is a universal school system based in Nigeria, offering online services and creating a secured school portal nationwide.
                    </p> */}
                    <p>
                        We understand that teachers have alot to do despite the little money in their paycheck. We have come to their rescue. We knows the stress, nightmare, and time it takes to manage an organisation especially a school that determins the future of our generation, we have come to give and provide quick and correct calculation and statistics in other to focus on somethig that matters and improve more in our academics.
                    </p>
                    <p>
                        Among the company’s services are career guidance, creating personal websites for schools, creating of students and staffs online profile, calculating the results of the students, printing the result in any part of the world, checking on student ability and improvement over the years, giving comments on the improvements, checking on the strength of a subject teacher and comparing it with previous teachers, tracking the improvement in the school growth, population and education wise. We also provide chance for the printing of the teacher result cass sheet. MySchoolgateway also offers motivational and valedatory speech to students especially graduating classes if we are given the opportunity to come and speak.
                    </p>
                    <p className="text-right" style={{"fontStyle": "italic", "fontWeight": 700}}><a href="#">MySchoolgateway</a></p>
                    <div className="title-but"><Link  to="/about-us" className="btn btn-general btn-green" role="button">Read More</Link></div>
                </div>
                </div>
            </div>
            </div>  
            <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s"> 
                <div className="story-descb">
                    <img src={`${process.env.PUBLIC_URL}/assets/main/img/termly_report.png`} className="img-fluid" alt="..." />
                    <h6>Termly Result</h6>
                    <p>We provide termly result of students by calculating the scores the teachers entered in all subjets and quiz. Students need a scratch card to unlock their termly result. The result contains a scannable QR code which will be used to verify the authenticity in this website</p>
                    {/* <a href="#"><i className="fa fa-arrow-circle-o-right"></i> Read More</a> */}
                </div>
                </div>
                <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s"> 
                <div className="story-descb">
                    <img src={`${process.env.PUBLIC_URL}/assets/main/img/annual_report.jpg`} className="img-fluid" alt="..." />
                    <h6>Annual Result</h6>
                    <p>Students have an option to print their annual result at the end of each academic year. The annual report contains the summary of all the report in that session. The third term scratch card unlocks the annual report.</p>
                    {/* <a href=""><i className="fa fa-arrow-circle-o-right"></i> Read More</a> */}
                </div>
                </div>
                <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s"> 
                <div className="story-descb">
                    <img src={`${process.env.PUBLIC_URL}/assets/main/img/transcript_report.jpg`} className="img-fluid" alt="..." />
                    <h6>Transcript of Records</h6>
                    <p>Students have the opporunity to print the transcript of their past years. Transcript is the collection of results per academic year. You can select a minimum of 2 and maximum of 3 terms. This is usually used by students who intend to study abroad.</p>
                    {/* <a href=""><i className="fa fa-arrow-circle-o-right"></i> Read More</a> */}
                </div>
                </div>                        
            </div>
            </div>  
        </section>
        {/* <span style={{"marginBottom":"-4000px"}}>Hello</span> */}
    </>
    )
}

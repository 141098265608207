import React, {Component} from 'react';
import {BrowserRouter as Router, Route,Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchLatestFeeds } from './redux/actions/blogActions'

import './App.css';
import Welcome from './main/pages/Welcome';
import Projects from './main/pages/Projects';
import Contact from './main/pages/Contact';
import Services from './main/pages/Services';
import About from './main/pages/About';
import Careers from './main/pages/Careers';
import Team from './main/pages/Team';
import FAQ from './main/pages/FAQ';
import Testimonials from './main/pages/Testimonials';
import Error404 from './main/pages/Error404';
import Pricing from './main/pages/Pricing';
import ComSoon from './main/pages/ComSoon';
import Affiliate from './main/pages/Affiliate';
import Disclaimer from './main/pages/Disclaimer';



import BlogHome from './blog/pages/BlogHome';
import FeedDetails from './blog/pages/FeedDetails';
import Categories from './blog/pages/Categories';
import Tags from './blog/pages/Tags';
import Terms from './main/pages/Terms';
import Policy from './main/pages/Policy';
import SchoolWelcome from './schools/pages/SchoolWelcome';
import SecondarySchool from './schools/pages/SecondarySchool';
import PrimarySchool from './schools/pages/PrimarySchool';

// import Welcome from './main/pages/Welcome';
// import AboutUs from './main/pages/AboutUs';
// import ContactUs from './main/pages/ContactUs';
// import Countries from './main/pages/Countries';
// import Courses from './main/pages/Courses';
// import CountryDetails from './main/pages/CountryDetails';
// import CourseDetails from './main/pages/CourseDetails';
// import Search from './main/pages/Search';
// import Error404 from './main/pages/Error404';

// import FeedDetails from './blog/pages/FeedDetails';
// import BlogWelcome from './blog/pages/BlogWelcome';
// import Tags from './blog/pages/Tags';
// import Categories from './blog/pages/Categories';



// import Main from './main/pages/Main';
// import Blog from './blog/pages/Blog';

class App extends Component{ 
  static propTypes = {
    latest: PropTypes.array.isRequired
  }
  state = { 
    loading: true
   }
  componentWillMount(){
    this.props.fetchLatestFeeds()
    // this.props.fetchSocials()
  }
  componentDidMount() { 
    // this simulates an async action, after which the component will render the content 
    // demoAsyncCall().then(() => this.setState({ loading: false })); 
    this.setState({loading:false})
  } 
  render() { 
    const { loading} = this.state; 
    const {latest} = this.props;
    if(loading) { 
        // if your component doesn't have to wait for async data, remove this block 
        return null; // render null when app is not ready 
    } 
    return ( 
      // <Router basename={`/sureways`}>
      // "homepage": "http://localhost/sureways",
      <Router >
        <Switch>


          {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Welcome} /> */}
          <Route exact path={`${process.env.PUBLIC_URL}/`} 
            render={(props) =>(
              <Welcome news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/team`} 
            render={(props) =>(
              <Team news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/services`} 
            render={(props) =>(
              <Services news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/projects`} 
            render={(props) =>(
              <Projects news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/FAQs`} 
            render={(props) =>(
              <FAQ news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/careers`} 
            render={(props) =>(
              <Careers news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/about-us`} 
            render={(props) =>(
              <About news={latest} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/testimonials`} 
            render={(props) =>(
              <Testimonials news={latest} {...props} />
            )} 
          />

          <Route exact path={`${process.env.PUBLIC_URL}/affiliates`} 
            render={(props) =>(
              <Affiliate news={latest} {...props} />
            )} 
          />

          <Route exact path={`${process.env.PUBLIC_URL}/schools/:town/:name`} 
            render={(props) =>(
              <SchoolWelcome key={`${props.match.params.name}`} {...props} />
            )} 
          />

          <Route exact path={`${process.env.PUBLIC_URL}/secondary/:name`} 
            render={(props) =>(
              <SecondarySchool key={`${props.match.params.name}`} {...props} />
            )} 
          />

          <Route exact path={`${process.env.PUBLIC_URL}/primary/:name`} 
            render={(props) =>(
              <PrimarySchool key={`${props.match.params.name}`} {...props} />
            )} 
          />

          <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={Contact} />

          <Route exact path={`${process.env.PUBLIC_URL}/comming-soon`} component={ComSoon} />
          <Route exact path={`${process.env.PUBLIC_URL}/disclaimer`} component={Disclaimer} />
          <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={Policy} />
          <Route exact path={`${process.env.PUBLIC_URL}/terms-and-condition`} component={Terms} />
          <Route exact path={`${process.env.PUBLIC_URL}/pricing`} component={Pricing} />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/testimonials`} component={Testimonials} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Services} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Careers} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/FAQs`} component={FAQ} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Projects} /> */}
          


          {/* Blog Routes */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={BlogHome} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/feeds/:date_added/:title`} 
            render={(props) =>(
              <FeedDetails key={`${props.match.params.date_added}`} {...props} />
            )} 
          /> */}

          <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={BlogHome} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/feeds/:date_added/:title`} 
            render={(props) =>(
              <FeedDetails key={`${props.match.params.date_added}`} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/category/:category`} 
            render={(props) =>(
              <Categories key={`${props.match.params.category}`} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/tag/:tag`} 
            render={(props) =>(
              <Tags key={`${props.match.params.tag}`} {...props} />
            )} 
          />



          {/* 404 ERROR page it must be the last Route */}
          <Route exact path="" component={Error404} />
          


          {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Welcome} /> */}
          {/* <Route exact path="/sureways" component={Welcome} /> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs} />
          <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={ContactUs} />
          <Route exact path={`${process.env.PUBLIC_URL}/countries`} component={Countries} />
          <Route exact path={`${process.env.PUBLIC_URL}/courses`} component={Courses} />
          <Route exact path={`${process.env.PUBLIC_URL}/courses/:school/:degree/:course`} 
            render={(props) =>(
              <CourseDetails key={`${props.match.params.school}_${props.match.params.degree}_${props.match.params.course}`} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/countries/:continent/:country`} 
            render={(props) =>(
              <CountryDetails key={`${props.match.params.continent}_${props.match.params.country}`} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/search/:keyword`} 
            render={(props) =>(
              <Search key={`${props.match.params.keyword}`} {...props} />
            )} 
          /> */}



          {/* Blog Routes */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={BlogWelcome} />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/feeds/:date_added/:title`} 
            render={(props) =>(
              <FeedDetails key={`${props.match.params.date_added}`} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/category/:category`} 
            render={(props) =>(
              <Categories key={`${props.match.params.category}`} {...props} />
            )} 
          />
          <Route exact path={`${process.env.PUBLIC_URL}/blog/tag/:tag`} 
            render={(props) =>(
              <Tags key={`${props.match.params.tag}`} {...props} />
            )} 
          />
          <Route exact path="" component={Error404} /> */}
        </Switch>
      </Router>
    ); 
  } 
} 
// function demoAsyncCall() { 
//   return new Promise((resolve) => setTimeout(() => resolve(), 2500)); 
// } 
const mapStateToProps = (state) => ({
  latest:state.blog.latestFeeds
})

const mapDispatchToProps = {
  fetchLatestFeeds
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
import React from 'react'

export default function WorkWithUs() {
    return (
        <>
        {/* <!--====================================================
                                CAREER-P1
        ======================================================--> */}

        <section id="about" className="career-p1 about">
        <div className="container">
            <div className="row title-bar">
            <div className="col-md-12">
                <h1 className="wow fadeInUp">Come work with us</h1>
                <div className="heading-border"></div>
                <p className="wow fadeInUp" data-wow-delay="0.4s">We are a bunch of people who embody the united spirit of our Team Adventure.</p>
            </div>
            </div>
        </div>
        <div className="container">
            <div className="row"> 
            <div className="col-md-6" >
                <div className="career-p1-himg" >
                <img src={`${process.env.PUBLIC_URL}/assets/main/img/image-4.jpg`} className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt=""/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="career-p1-desc">
                <h4>We Make from the Best</h4>
                <div className="heading-border-light"></div>
                <p>We are a bunch of people who embody the united spirit of our Team Adventure. While you are working your hardest on opportunities that will define your career, Businessbox makes sure your environment provides for you</p> 
                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years.</p>
                <ul>
                    <li><i className="fa fa-arrow-circle-o-right"></i> Champion the Mission.</li>
                    <li><i className="fa fa-arrow-circle-o-right"></i> Great Career Options.</li>
                    <li><i className="fa fa-arrow-circle-o-right"></i> Full flexiblity of life.</li> 
                </ul>
                </div>
            </div>  
            </div>
        </div> 
        </section>  

        {/* <!--====================================================
                                CAREER-P2
        ======================================================--> */}
        <div className="overlay-career-p2"></div>
        <section id="thought" className="bg-parallax career-p2-bg">
        <div className="container">
            <div id="row" className="row title-bar-career-p2">
            <div className="col-md-4 ">
                <h1 >100%</h1>
            </div>  
            <div className="col-md-8 ">
                <p className="wow fadeInUp " data-wow-delay="0.4s">Businessbox will deliver value to all the stakeholders and will attain excellence and leadership through such delivery of value. We will strive to support the stakeholders in all activities related to us.</p>
            </div> 
            </div>
        </div>         
        </section> 

        {/* <!--====================================================
                            CAREER-P3
        ======================================================-->  */}
        <div className="overlay-career-p3"></div>
        <section id="career-p3">
        <div className="container-fluide">
            <div className="row career-p3-title">
            <div className="col-md-12">
            <h3 className="text-center">Our Business Process</h3>
            </div> 
            </div>
            <div className="row">
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-superpowers"></i>
                <h5>Plan</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-snowflake-o"></i>
                <h5>Seamless</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-send-o"></i>
                <h5>Care</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-sun-o"></i>
                <h5>Transit</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-crosshairs"></i>
                <h5>Vacation</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-gift"></i>
                <h5>Enjoy</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-plane"></i>
                <h5>Celebrate</h5>
                </div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="career-p3-cont text-center">
                <i className="fa fa-life-buoy"></i>
                <h5>Contrary</h5>
                </div>
            </div>
            </div>
        </div>
        </section> 
        </>
    )
}

export default {
    FETCH_FEEDS: 'FETCH_FEEDS',
    FETCH_POPULAR_FEEDS: 'FETCH_POPULAR_FEEDS',
    FETCH_LATEST_FEEDS: 'FETCH_LATEST_FEEDS',
    FETCH_FEED_DETAILS: 'FETCH_FEED_DETAILS',
    FETCH_TAGS: 'FETCH_TAGS',
    FETCH_CATEGORIES: 'FETCH_CATEGORIES',
    FETCH_HOT_CATEGORIES: 'FETCH_HOT_CATEGORIES',
    FETCH_ADMIN: 'FETCH_ADMIN',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',
    // FETCH_: 'FETCH_',

    MAIN_ONLINE:'https://myschoolgateway.com/lib/php/rest_api/api.blog.php',
    MAIN_OFFLINE:'http://ohaneze/myschoolgateway.com/lib/php/rest_api/api.blog.php',
}
import React from 'react'
import parser from 'html-react-parser';

import AffiliateTestimonies from './AffiliateTestimonies';
import Features from './Features';

export default function AffiliateInfo() {
    return (
        <>            
            <section id="project-p1" className="project-p1">
                <div className="container-fluid">
                    <div className="  row">
                        <div className="col-sm-6 col-xs-12 bg-matisse" style={{cursor:"pointer"}} onClick={()=>window.location.assign('https://myschoolgateway.com/affiliate/register.php')}>
                            <div className="project-p1-cont wow fadeInUp  text-center" data-wow-delay="0.3s">
                                <i className="fa fa-user-plus fa-2x"></i>
                                <p>Create account now</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 bg-atlis" style={{cursor:"pointer"}} onClick={()=>window.location.assign('https://myschoolgateway.com/affiliate/login.php')}>
                            <div className="project-p1-cont wow fadeInUp  text-center" data-wow-delay="0.9s">
                                <i className="fa fa-user-circle fa-2x"></i>
                                <p>Login to your account</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="business-growth-p1" className="business-growth-p1 bg-gray">
                <div className="container">
                    <div className="row title-bar">
                        <div className="col-md-12">
                            <h1 className="wow fadeInUp">We committed to helping</h1>
                            <div className="heading-border"></div>
                            <p className="wow fadeInUp" data-wow-delay="0.4s">Myschoolgateway searched for a way to empower youths by helping them to make money through Myschoolgateway and Affiliate Marketing is the only answer so far.</p>
                        </div>
                    </div>
                    <Features />
                </div>  
            </section> 
            {/* <!--====================================================
                                FINANCIAL-P1
            ======================================================-->      */}
            <section id="financial-p1" className="bg-parallax financial-p3-bg">
                <div className="overlay-financial-p1"></div>
                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 financial-p1-pos">
                        <div className="financial-p1-cont">    
                            <a href="#"> <b>Introduction</b></a>  
                            <h3>What is Affiliate Marketing?</h3>
                            <p>Affiliate in a nutshell is a way to get someone involve or initiate into a society or program which will make the person involve in the program `plans` and bear their `mystries` too. <br/>Running an affiliate program with Myschoolgateway is nothing more than introducing and conviencing schools to use Myschoolgateway web portal. So to be able to do this you will know the core values and importance of myschoolgateway because you can only convince people to join what you know about. You will act as a middleman between the school you connected to Myschoolgateway with Myschoolgateway. <br/>
                            NOTE: while registering the school enter the email you used to signup as an affiliate in the last textbox with the description `Who refferred you to the website?`
                            </p>                                
                            <a href="https://myschoolgateway.com/affiliate/register.php" className="btn btn-general btn-white btn-green" role="button">Register now</a>
                        </div>
                    </div>
                    <div className="col-md-6 financial-p1-emp-img"></div>
                </div>
                </div>
            </section> 
            
            <section id="financial-p1" className="bg-parallax get-started-bg">
                <div className="overlay-financial-p1"></div>
                <div className="container-fluid">
                <div className="row"> 

                    <div className="col-md-6 financial-p1-emp-img d-md-block d-sm-none"></div>
                    <div className="col-md-6 financial-p1-pos financial-p1-emp-desc-d_">
                        <div className="financial-p1-cont">    
                            <a href="#"> <b>How to get Started</b></a>  
                            <h3>How can i get involve?</h3>
                            <p>To get started you will  first register in this website then join Myschoolgateway <a href='https://chat.whatsapp.com/F4YNyUgFZM65VQmbSQt7QB' target='_empty'>affiliate group on WhatsApp</a> where the new features of Myschoolgateway are first broadcast in, and affiliate marketers will also come up with suggestions for new features which has a price for any accepted feature. Affiliates will still get paid if they report any error in Myschoolgateway website to the Myschoolgateway officials. They will also share their difficulties and problems they faced from schools. This is being involve in Myschoolgateway plans as stated above. <br/> Then being part of the mystery is a very simple explanation so do not be worried about it. Myschoolgateway pays affiliates on percentage from what Myschoolgateway get from the school the affiliate connected to the comapany. So whenever the school has a decline in population there will be a decline in the affiliate payment for the particular school.</p>     
                            <a href='https://chat.whatsapp.com/F4YNyUgFZM65VQmbSQt7QB' className="btn btn-general btn-white btn-green" role="button"><i className="fa fa-whatsapp"></i> Join WhatsApp Group</a>
                        </div>
                    </div>
                    <div className="col-md-6 financial-p1-emp-img d-md-none"></div>
                </div>
                </div>
            </section>

            <section id="financial-p1" className="bg-parallax benefits-bg">
                <div className="overlay-financial-p1"></div>
                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 financial-p1-pos">
                    <div className="financial-p1-cont">    
                        <a href="#"> <b>Benefits</b></a>  
                        <h3>What are my benefits?</h3>
                        
                        <div className="career-p1-desc">
                            <div className="heading-border-light"></div>
                            <p>These are few benefits affiliates get from marketing Myschoolgateway system.</p>
                            <ul style={{fontSize:14}}>
                                <li><i className="fa fa-arrow-circle-o-right"></i> Affiliates recieves {parser('&#8358;')}5,000 sign up bonus</li>
                                <li style={{marginBottom:20}}><i className="fa fa-arrow-circle-o-right"></i>MySchoolGateway will pay you 40% markup on the profits earned from supplying termly result scratch cards or result checkers to the school you referred to us.</li>
                                <li style={{marginBottom:20}}><i className="fa fa-arrow-circle-o-right"></i> You will get paid for suggesting an improvement to Myschoolgateway</li>
                                <li><i className="fa fa-arrow-circle-o-right"></i> You will also get paid for reporting an error or bug to the system</li>
                                <li><i className="fa fa-arrow-circle-o-right"></i> Sometimes Myschoolgateway pay seasonal bonus to affiliates</li>
                                <li><i className="fa fa-arrow-circle-o-right"></i> Myschoolgateway pay directory to the affiliate bank account in less than 48 hours they submitted for withdrawal</li>
                            </ul>
                        </div>
                        {/* <div className="career-p1-desc">
                            <div className="heading-border-light"></div>
                            <p>These are few benefits schools get from running Myschoolgateway system.</p>
                            <ul>
                                <li><i className="fa fa-arrow-circle-o-right"></i> It is a private company so it is always up and running and it provides the best servics and quick response to the users<br/><br/></li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> It is technically secured from hackers or any form of cyber crime</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> Your data is secured and can always be assessable at anytime</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> It is fast and accurate</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> It is fully customizable</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> It has fast and secured payment gateways</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> Each student/Staff has their personal dashboard and messaging system</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> Each school has their personal homepage</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> It is trusted by many</li> 
                                <li><i className="fa fa-arrow-circle-o-right"></i> Each student get a notification SMS when the school ICT administrator publish the result</li>
                                <li><i className="fa fa-arrow-circle-o-right"></i> It increases the standard of the school</li>
                            </ul>
                        </div> */}
                    </div>
                    </div>
                    <div className="col-md-6 financial-p1-emp-img d-sm-none d-md-block"></div>
                </div>
                </div>
            </section> 

            {/* <!--====================================================
                                AFFILIATE TESTIMONIES
            ======================================================-->     */}
            <AffiliateTestimonies />
            
            

            {/* <!--====================================================
                                FINANCIAL-P6
            ======================================================--> */}
            <section id="financial-p6" className="financial-p6"> 
                <div className="container">
                <div className="row">
                    <div className="col-md-6">
                    <div className="financial-p6-img">
                        <img src={`${process.env.PUBLIC_URL}/assets/main/img/image-3.jpg`} className="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="..."/>
                        <img src="" alt=""/>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="financial-p6-cont">
                        <h2 className="wow fadeInUp">How it Works</h2>
                        <div className="heading-border-light"></div>
                        <p>
                          If you register as an affiliate you will get instant #5,000 bonus in your account. When you introduce a school to Myschoolgateway, while registering the schools, add your affiliate email address as the referrer and the school will be updated in your dashboard. 
Whenever the school make a purchase of termly result checker/scratch card from the portal or physical payment, Myschoolgateway system will automatically reflect a 40% of the mark up profit in your portal. The markup profit calculator is provided inside the affiliate dashboard. 
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

import React from 'react'
import { Link } from 'react-router-dom';
import parser from 'html-react-parser'

export default function EachFeed(props) {
    const {title,body,date,admin,category,image,total_comments,link} = props.feed
    let comments = ""
    if(total_comments == 0){
        comments = "Nil"
    }
    else if(total_comments == 1){
        comments = "1 Comment"
    }
    else{
        comments = total_comments+" Comments";
    }
    return (
        <>
            <div className="single-news-p1-cont" style={{"marginBottom": "30px", "boxShadow": "1px 1px 1px rgba(0,0,0,0.1)"}}>
                <Link to={link} className="single-news-img">
                    <img src={`${process.env.PUBLIC_URL}/bucket/${image}`} alt="[Feed Photo]" className="img-fluid" style={{maxHeight:300}} />
                </Link>
                <div className="single-news-desc m-0 pt-1 pb-0 px-0">
                    <h3>{title}</h3>
                    <ul className="list-inline">
                        <li>Posted: <span className="text-theme-colored2"> {date}</span></li>
                        <li>By: <span className="text-theme-colored2">{admin}</span></li>
                        <li><i className="fa fa-briefcase"></i>  <span className="text-theme-colored2">{category}</span></li>
                        <li><i className="fa fa-comments-o"></i> {comments} </li>
                    </ul>
                    <hr/>
                    <div className="bg-light-gray">
                        <p>{parser(body)}</p> 
                        <Link to={link} className="mb-2">Read More <i className="fa fa-long-arrow-right"></i></Link>
                    </div> 
                </div>
            </div>
        </>
    )
}

import React, { useState } from 'react'
import AffiliateTestimonies from './AffiliateTestimonies';

export default function Testimonies() {

    const [testimonies, setTestimonies] = useState([        
      {
        name:"Mr. Ifeanyi Daniel",
        photo:"sgw_male.png",
        work:"Principal - Zion Secondary School",
        message:"Myschoolgateway brought alot of positive changes to my schoool. It facilitated most school activies, helps to track down the strenght of teachers and how to redeploy them to another class"
      },
      {
        name:"Mr. Edeh Iyke",
        photo:"sgw_male.png",
        work:"Teacher - Royal Secondary School",
        message:"Myschoolgateway ease work alot for us expecially class head teachers. It calculates the result fast and student doesnt have any problem/delay with printing out the result"
      },
      {
        name:"Okafor Chioma",
        photo:"sgw_female.png",
        work:"Student - Domino International Secondary",
        message:"With myschoolgateway I have more holidays, because we do not need to come back to school to take our result a week after the exam."
      },
      {
        name:"Mr. Adeleke James",
        photo:"sgw_male.png",
        work:"Teacher - Apostolic Faith Secondary School",
        message:"Myschoolgateway is the best online school portal I have use since I started my teaching career"
      }
    ])
    return (
        <>
            {/* <!--====================================================
                            TESTIMONIALS-P1
            ======================================================--> */}
            <div className="container">
                <div className="row title-bar">
                <div className="col-md-12">
                    <h1 className="wow fadeInUp">Our Client Thoughts</h1>
                    <div className="heading-border"></div>
                    <p className="wow fadeInUp" data-wow-delay="0.4s">We are a bunch of people who embody the united spirit of our Team Adventure.</p> 
                </div>
                </div>
            </div>  
            <div className="overlay-testimonials-p1"></div>
            <AffiliateTestimonies />

        {/* <!--====================================================
                            TESTIMONIALS-P2
        ======================================================--> */}
            <section id="client" className="client testimonials-p2">
                <div className="container"> 
                    <div className="row">
                    {
                        testimonies.map(testify=>(
                            <div className="col-md-6 col-sm-12">
                                <div className="client-cont">
                                <img src={`${process.env.PUBLIC_URL}/assets/main/img/${testify.photo}`} className="img-fluid" alt="" />
                                <h5>{testify.name}</h5>
                                <h6>{testify.work}</h6>
                                <i className="fa fa-quote-left"></i>
                                <p>{testify.message}</p>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>        
            </section> 
        </>
    )
}

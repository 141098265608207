import { createStore, applyMiddleware,compose,combineReducers } from 'redux'
import thunk from 'redux-thunk'
import mainReducer from './redux/reducers/'

const initialState = {}

const middleware = [thunk]

const store = createStore(
    // rootReducer,
    mainReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
)

export default store
import React, { Component } from 'react'
import {Link } from 'react-router-dom';
import { linkEncode } from '../../Funcs';

class News extends Component {
    render() {
        const {desc,btn,btnLink,feeds} = this.props
        return (
		<section id="comp-offer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-sm-6  desc-comp-offer wow fadeInUp" data-wow-delay="0.2s">
                        <h2>{desc}</h2>
                        <div className="heading-border-light"></div> 
                        <Link to={btnLink} className="btn btn-general btn-green" role="button" style={{display:btn?"block":"none"}} >{btn}  </Link>
                    </div>
                    {
                        feeds.map(feed=>(
                            <Link to={linkEncode(feed.link)} key={feed.id} style={{textDecoration:"none"}} className="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.4s">
                                <div className="desc-comp-offer-cont">
                                <div className="thumbnail-blogs">
                                    <div className="caption">
                                        <i className="fa fa-chain"></i>
                                    </div>
                                    <img src={`${process.env.PUBLIC_URL}/bucket/${feed.image}`} className="img-fluid" alt="..."  style={{height:220, width:555 }}/>
                                </div>
                                <h3>{feed.title}</h3>
                                <p className="desc"> {feed.body}</p>
                                <span style={{color:"blue",marginLeft:"10px"}}><i className="fa fa-arrow-circle-o-right"></i> Read More</span>
                                </div>
                            </Link>
                        ))
                    }
                    
                </div>
            </div>
        </section>
        )
    }
}

export default News

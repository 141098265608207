import React, { Component } from 'react'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'

import HomeBanner from '../components/HomeBanner'
import News from '../components/News';
import OurStory from '../components/OurStory';
// import AffiliateTestimonies from '../components/AffiliateTestimonies';
import QuickContact from '../components/QuickContact';
import HomeService from '../components/HomeService';

export default class Welcome extends Component {
  state = {
    testimonies:[
      {
        name:"Mr. Ifeanyi Daniel",
        photo:"sgw_male.png",
        work:"Principal - Zion Secondary School",
        message:"Myschoolgateway brought alot of positive changes to my schoool. It facilitated most school activies, helps to track down the strenght of teachers and how to redeploy them to another class"
      },
      {
        name:"Mr. Edeh Iyke",
        photo:"sgw_male.png",
        work:"Teacher - Royal Secondary School",
        message:"Myschoolgateway ease work alot for us expecially class head teachers. It calculates the result fast and student doesnt have any problem/delay with printing out the result"
      },
      {
        name:"Okafor Chioma",
        photo:"sgw_female.png",
        work:"Student - Domino International Secondary",
        message:"With myschoolgateway I have more holidays, because we do not need to come back to school to take our result a week after the exam."
      },
      {
        name:"Mr. Adeleke James",
        photo:"sgw_male.png",
        work:"Teacher - Apostolic Faith Secondary School",
        message:"Myschoolgateway is the best online school portal I have use since I started my teaching career"
      }
    ]
  }
  render() {
    const {testimonies} = this.state
    return (
      <> 
        <Header/>
          <HomeBanner/>
          <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
          {/* <News feeds={this.props.news} desc="WHAT WE OFFER" btn="Contact Us Today" btnLink="/contact" /> */}
          <OurStory />
          {/* <AffiliateTestimonies /> */}
          <HomeService testimonies={testimonies} />
          <QuickContact />
        <Footer/>  
      </>
    )
  }
}
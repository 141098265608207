import React, { Component } from 'react'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import News from '../components/News';
import PageIntro from '../components/PageIntro';
import WorkWithUs from '../components/WorkWithUs';
import '../../assets/main/css/careers.css'

class Careers extends Component {
    render() {
        return (
            <>
                <Header />
                <PageIntro intro="We offer a huge opportunity for growth" design="pages-head2" title="Careers" />
                <WorkWithUs />
                <News feeds={this.props.news} desc="Latest News" btn="See More" btnLink="/blog" />
                <Footer />
            </>
        )
    }
}

export default Careers

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setSchSession,fetchSchools } from '../../redux/actions/mainActions'
import { setCookie } from '../../Funcs';


class SchoolWelcome extends Component {
    componentWillMount(){
        let {town,name} = this.props.match.params
        setSchSession("type1",town,name);
        // this.props.fetchSchools();
    }
    // componentDidMount(){
    //     setTimeout(() => this.getSchool(), 1000);
    // }
    // getSchool = () =>{
    //     let {town,name} = this.props.match.params
    //     var schoolInfo = [...this.props.schools].filter(school=> school.link1.toLowerCase() == `https://myschoolgateway.com/schools/${town}/${name}`.toLowerCase());
    //     if(schoolInfo.length > 0){
    //         setCookie("mysgwid",schoolInfo[0].mysgwid,1);
    //         window.location.assign("https://myschoolgateway.com/myschool/");
    //         // window.location.assign(schoolInfo[0].link1)
    //         // console.log(schoolInfo[0].link1);
    //     }
    //     else{
    //         alert("The link you entered does not point to any school in our database. Please search for the school name in our homepage");
    //         window.location.assign("https://myschoolgateway.com/");
    //     }
    // }
    render() {
        return (
            <>
                
            </>
        )
    }
}

const mapStateToProps = (state) => ({    
	// schools: state.main.schools,
})

const mapDispatchToProps = {
    // fetchSchools
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolWelcome)

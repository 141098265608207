import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parser from 'html-react-parser'

class Faqs extends Component {
    state = {
        faq:"",
        schoolFaq:[
            {
                question:"How can I get started with Myschoolgateway?",
                answer:"Myschoolgateway is an easy and self explanatory school system. <a href='https://www.myschoolgateway.com/registration.php' target='_empty'> Click here</a> to go to the registration page. Fill in the four(4) step forms and log in as the ICT administrator. Then start creating your classes/Class head teacher. <a href='https://www.youtube.com/channel/UC-CeX2ZhgV3jziC1EpuzSGw' target='_empty'> click here</a> to watch our youtube tutorial about myschoolgateway",                                            
            },
            {
                question:"How much does it cost to use your services?",
                answer:"Myschoolgateway is free for all the basics need of a school from the registration to managing the student and staff statistics. The result calculation is free likewise the teacher cast sheet but there is a price for each student to view and print their result. The information about pricing can be seen at <a href='https://www.myschoolgateway.com/pricing' target='_empty'>Pricing Page</a>",                                            
            },
            {
                question:"Do my students need scratch card or any card to check their result?",
                answer:"Yes but it depends on suscription. There are two options for schools which are card and no card base options. If the school demand a scratch card from mySchoolgateway we will deliver the number of requested scratch card to them within 5 - 10 working days  depending on the school location. The school sctill have option to unlock the student results by themselves there no need for a scratch card. The price on the two options are different <br/> NOTE: Each student has their login details which they will use to gain access to their portal but they will only see the result they paid for or they have unlocked with the scratch card.",                                            
            },
            {
                question:"Can I have the teacher's sheet after calculating the result?",
                answer:" Yes you can, the teacher's sheet will be printed in sheets of an A4 paper according to the number of student in the class.",                                            
            },

            {
                question:"What is different between the Myschoolgateway and other school system?",
                answer:"It depends on the school system you are comparing, but Myschoolgateway has a nice feature that is second to none, It is stress free, fast, reliable, has no calculation error and calculates the results of the student by itself within 5 seconds depending on the strength of your internet. <br/><br/>Registration into myschoolgateway is free, it is highly customisable to any school taste. It universal which make it to have alot of quality, and schools can suggest an option which they want that is not available in the system, if the option is voted as important it will be added for all.<br/> Price for student scratch card is cheap and a school can request for their own .com website which the will maintain with an annual fee. Visit our <a href='https://www.myschoolgateway.com/pricing' target='_empty'>Pricing Page</a> for more details",                                            
            },
            {
                question:"Do I need a personal school website while using the Myschoolgateway?",
                answer:"Yes you can, Myschoolgateway does not call for the shut down of your existing website, You can use your personal school website as an aid to marketing of your school. You can also configure your school website which you did not get from myschoolgateway to be using Myschoolgateway result portals. <a href='https://www.myschoolgateway.com/contact-us' target='_empty'>Contact us foir more explanation on this</a> <br/> Above all myou can request for a personal .com website from Myschoolgateway whcih you will get immediately with no technical knowledge.Visit our <a href='https://www.myschoolgateway.com/pricing' target='_empty'>Pricing Page</a> for more details",                                            
            },
            {
                question:"What are the benefits of the Myschoolgateway?",
                answer:"Myschoolgateway is an easy and self explanatory school system. Any teacher can use it with a little computer knowledge. If you are a teacher, and you are on a social network you can use Myschoolgateway. It has a nice feature that is second to none, It is stress free, fast, reliable, has no calculation error and calculates the results of the student by itself within 5 seconds.",                                            
            },
            {
                question:"How much can I trust Myschoolgateway in calculating my student's result?",
                answer:" Once you registered your subjects, students and classes well, the next possible error is typographical error. For the calculation of the result, it has been tested and trusted by many, it is error free!!",                                            
            },
            {
                question:"I already have my school system, do I still need Myschoolgateway?",
                answer:"There is nothing wrong in trying, you can test your school in one academic year and compare the differences, You can also reach out to schools that are using it and ask the teachers and students about their experience. The bonus is that Myschoolgateway has a free term the after your registration, this can be called a demo term which a school will not pay for if they choose a no scratch card option but if they choose to get their scratch card they will recieve a 50% off in the scratch card price",                                            
            },
            {
                question:"Is it easy to start Myschoolgateway? Do i need any technical knowledge to get started?",
                answer:"Myschoolgateway is an easy and self explanatory school system, most acions and and buttons are explained. It requires a read and write knowledge and a little computer knowledge. Any teacher can use it with a little computer knowledge. If you are a teacher, and you are on a social network you can use Myschoolgateway. Most students are now on social media, they can access all features of Myschoolgateway with the knowlegde of social media",                                            
            },
            {
                question:"Most of my school staffs are not computer literate what will I do?",
                answer:"One amazing thing about Myschoolgateway is that you can do most things on it alone. Each school has a ICT administrator account, who can be the computer teacher. If the school turn on One man show Option in ICT administrator settings page it means he can calculate the reslt of the whole class by itself which will be done by inputting the scores of students in each class. We also have a mobile app which is focus on entering the result of the students which is faster. In a case where the school do not have capable hand to do such you can <a href='https://www.myschoolgateway.com/contact-us' target='_empty'>contact us</a> to assign a data entry personel to you which will do the work for your school",                                            
            },
            {
                question:"Can I use my mobile phone to run Myschoolgateway?",
                answer:"If you have Chrome, firefox browser you can use your tablet or a wide screen android to run Myschoolgateway by setting the view of the browser to desktop and zoom in. In terms of entering the scores of the students it is not advisable to use a mobile browser. Download the Myschoolgateway mobile app",                                            
            },
            {
                question:"What are the requirements to open a Myschoolgateway account?",
                answer:"There is no registeration fee required just <a href='https://www.myschoolgateway.com/contact-us' target='_empty'>contact us</a>  and request for a coupon code which you will use for your school registration . Your school ICT administrator or any teacher that has the knowledge of computer in relation to internet can do the rest of the job you cant conatct us on <a href='https://www.wa.me/2349039146415' target='_empty'> WhatsApp to help out with the registration</a> But since Myschoolgateway is a self explantory system, visit our <a href='https://www.youtube.com/channel/UC-CeX2ZhgV3jziC1EpuzSGw' target='_empty'> youtube page</a> and watch the beginners video.",                                            
            },
            {
                question:"Can I print the Previous results of my class, using Myschoolgateway",
                answer:" Yes!! Teachers and students can print the result from the time you registered your school to the current school year. The students can print only the result they paid for",                                            
            },
            {
                question:"Is this school system secured enough?",
                answer:"Yes it is secured, but you can help us to secure it by securing your login details by not sharing it to anyone..",                                            
            },
            {
                question:"What if I encounter any problem while using Myschoolgateway?",
                answer:"If you encounter any problem anywhere while using the schoool system <a href='https://www.myschoolgateway.com/contact-us' target='_empty'>Contact us</a> Or contact us for a quick reply on <a href='https://www.wa.me/2349039146415' target='_empty'> WhatsApp</a> to help you out with anything at any time",                                            
            }
        ],
        affiliateFaq:[
            {
                question:"What is Myschoolgateway affiliate program?",
                answer:"Myschoolgateway searched for a way to empower youths by helping them to make money through Myschoolgateway and Affiliate Marketing is the only answer so far. So affiliate in a nutshell is a way to get someone involve or initiate into a society or program which will make the person involve in the program `plans` and bear their `mystries` too. <br/>Running an affiliate program with Myschoolgateway is nothing more than conviencing schools to use Myschoolgateway web portal. So to be able to do this you will know the core values and importance of myschoolgateway because you can only convince people to join what you know about. You will act as a middleman between the school you connected to Myschoolgateway with Myschoolgateway. You will join Myschoolgateway <a href='https://chat.whatsapp.com/F4YNyUgFZM65VQmbSQt7QB' target='_empty'>affiliate group on WhatsApp</a> where the new features of Myschoolgateway are first broadcast in, and affiliate marketers will also come up with suggestions for new features which has a price for any accepted feature. Affiliates will still get paid if they report any error in Myschoolgateway to the Myschoolgateway officials They will also share their difficulties and problems they faced from schools. This is being involve in Myschoolgateway plans as stated above. <br/> Then being part of the mystery is a very simple explanation so do not be worried about it. Myschoolgateway pays affiliates on percentage from what Myschoolgateway get from the school the affiliate connected to the comapany. So whenever the school has a decline in population there will be a decline in the affiliate payment for the particular school.",                                            
            },
            {
                question:"How to get started?",
                answer:"To register as Myschoolgateway affiliate program is simple. <a href='https://www.myschoolgateway.com/affiliate/register.php' target='_empty'> Click here to register</a> yourself and get #5000 registration bonus. ",                                            
            },
            {
                question:"How can I get paid?",
                answer:"As for the time of writing this 22nd January 2020 Myschoolgateway does not have an automated payment system which will pay an affiliate marketer immediately s/he click on withdrawal. Therefore an affiliate marketer will submit for withdrawal and wait for the money to be transferred to his/her bank account. The money will be transferred within 1-5 working days",                                            
            },
            {
                question:"How Quick is the payment",
                answer:"It depends on the payment. Different payments are paid on different interval. If an error is reported to myschoolgateway and the error is verified to be true the payment is immediately. If a new improvement is suggested for Myschoolgateway and the improvement is marked as importance by the board, the payment will be transferred immediately. Data entry jobs are paid immediately the school send the payment. Myschoolgateway pays affiliate marketers their percentage from school that is shown in the website as balance from 1-5 working days after s/he submit for withdrawal",                                            
            },
            {
                question:"Is there any additional Incentives apart from the payment from schools",
                answer:"Yes there are many rewards from Myschoolgateway such as: being a data entry, yearly bonus, registration bonus, bug report, new improvement report. <ul><li><b style='font-weight:bold;'>Data Entry: </b> You can do a data entry job for a school by helping them put the scores of their students into Myschoolgateway system. Myschoolgateway will can also assign you to do it for a school which you are not the one that registered them and the payment for this is immediately after you complete the task</li> <li><b style='font-weight:bold;'>Yearly Bonus: </b> Myschoolgateway might decide to give out a yearly bonus to all affiliate marketers as a compensation for running a successful year. This is independent of the affiliate account but the affiliate must have a school registered with to recieve the affiliate yearly bonus</li>  <li><b style='font-weight:bold;'>Registration Bonus: </b> Affiliates get a registration bonus of 5000NGN after registration but this money will only be withdrawed if the affiliate marketer make up to 5000NGN from any of the school registered </li> <li><b style='font-weight:bold;'>Bug Reporting: </b> If a user report an error or a bug found in the website to Myschoolgateway s/he will recieve money from the company once the error is verified to be true</li> <li><b style='font-weight:bold;'>New Improvement Report: </b> Affilate marketers will also earn if they suggest a new improvement and the improvement is accepted by myschoolgateway board.</li></ul>"    
            },
            {
                question:"How can I get more schools?",
                answer:"To get more schools in Myschoolgateway you have to meet more schools and introduce it to them. You can also tell schools you are working with to suggest other schools for you or help you to get more schools. You can make a broadcast for schools to register with your email address",
            }
        ],
        paymentFaq:[
            {
                question:"",
                answer:"",                                            
            },
            {
                question:"",
                answer:"",                                            
            },
            {
                question:"",
                answer:"",                                            
            },

            {
                question:"",
                answer:"",                                            
            },
            {
                question:"",
                answer:"",                                            
            },
            {
                question:"",
                answer:"",                                            
            },
        ]
    }

    toggleFAQ = (faq) =>{
        this.setState((prevState) =>{
            if(prevState.faq == faq){
                return {
                    faq:""
                }
            }
            else{
                return {
                    faq
                }
            }
			
		})
    }

    render() {
        const dFaq = (current) =>{
            return this.state.faq == current?true:false;            
        }  
        
        const {toggleFAQ} = this
        const {schoolFaq,affiliateFaq} = this.state
        return (
            <>
            
        {/* <!--====================================================
                            FAQ-P1
        ======================================================--> */}
        <section id="faq-p1" className="team-p1">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="faq-p1-cont">
                    <div className="service-h-tab"> 
                        <nav className="nav nav-tabs" id="myTab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-schools-tab" data-toggle="tab" href="#nav-schools" role="tab" aria-controls="nav-schools">Schools</a> 
                            <a className="nav-item nav-link" id="nav-affiliate-tab" data-toggle="tab" href="#nav-affiliate" role="tab" aria-controls="nav-affiliate">Affilate Account</a> 
                            {/* <a className="nav-item nav-link" id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-expanded="true">Payment</a> */}
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-schools" role="tabpanel" aria-labelledby="nav-schools-tab">
                                {
                                    schoolFaq.map((faq,index)=>{
                                        return(
                                            <div className="toggle" key={index}>
                                                <div className="toggle-title" onClick={()=>toggleFAQ('schfaq'+index)}>
                                                    <h3>
                                                    <i></i>
                                                    <span className="title-name">{faq.question}</span>
                                                    </h3>
                                                </div>
                                                <div className="toggle-inner" style={{display:dFaq('schfaq'+index)?"block":"none"}}>
                                                    <p>{parser(faq.answer)}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        
                            <div className="tab-pane fade" id="nav-affiliate" role="tabpanel" aria-labelledby="nav-affiliate-tab">
                                {
                                    affiliateFaq.map((faq,index)=>{
                                        return(
                                            <div className="toggle" key={index}>
                                                <div className="toggle-title" onClick={()=>toggleFAQ('affiliatefaq'+index)}>
                                                    <h3>
                                                    <i></i>
                                                    <span className="title-name">{faq.question}</span>
                                                    </h3>
                                                </div>
                                                <div className="toggle-inner" style={{display:dFaq('affiliatefaq'+index)?"block":"none"}}>
                                                    <p>{parser(faq.answer)}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="tab-pane fade" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab"> 
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>      
            </section>   
            </>
        )
    }
}

export default Faqs;

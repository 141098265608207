import React from 'react'

export default function EachTestimony(props) {
    const {photo,name,work,message} = props.testimony
    return (
        <div className="col-md-6 col-sm-12">
            <div className="client-cont wow fadeInUp" data-wow-delay="0.3s">
            <img src={`${process.env.PUBLIC_URL}/assets/main/img/${photo}`} className="img-fluid" alt=""/>
            <h5>{name}</h5>
            <h6>{work}</h6>
            <i className="fa fa-quote-left"></i>
            <p>{message}</p>
            </div>
        </div>
    )
}

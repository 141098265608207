import React,{Component} from 'react'
import parser from 'html-react-parser'

export default class Comments extends Component {
    state = {
      email:"",
      name:"",
      message:"",
      toReply:{
          id:0,
          name:"",
          message:""
      },
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onSubmitForm = (e) =>{
        e.preventDefault();
        const {name,email,message,toReply} = this.state
        this.props.submitComment(name,email,message,toReply.id);
        this.setState({
            name:"",
            email:"",
            message:"",
        })
        this.resetReply();
    }
    setReply = (id,name,message) =>{
        this.setState({
            toReply:{
                id,
                name,
                message
            }
        })
    }
    resetReply = () => {
        this.setState({
            toReply:{
                id:0,
                name:"",
                message:""
            }
        })        
    }

    render(){
        const {name,email,message,toReply} = this.state
        const {comments} = this.props

        const Reply = ({reply}) =>{
            let from = (reply.from == 'admin')?"by-author":"";
            return(
                <li>
                    <div className="comment-avatar"><img src={`${process.env.PUBLIC_URL}/bucket/${reply.image}`} alt=""/></div>
                    {/* <!-- Contenedor del Comentario --> */}
                    <div className="comment-box sub-comment-box">
                        <div className="comment-head">
                        <h6 className={`comment-name ${from}`}><a href="#">{reply.name}</a></h6>
                        <span>{reply.date}</span>
                        {/* <i className="fa fa-reply"></i>
                        <i className="fa fa-heart"></i> */}
                        </div>
                        <div className="comment-content">
                            {parser(reply.message)}
                        </div>
                    </div>
                </li>
            )
        }
        const replyMessage = (toReply) =>{
            if(toReply.id === 0){
                return(
                    <h5>Leave a Reply</h5>
                )
            }
            else{
                return(
                    <h5 style={{fontSize:"12px"}}>Replying to {toReply.name} comment '{toReply.message.substr(0,34)}...'    <button style={{marginLeft:'20px'}} className="btn btn-sm btn-danger" onClick={()=>this.resetReply()}>X</button></h5>
                )
            }
        }

        return (
            <>
                <div className="comments-container"> 
                    <ul id="comments-list" className="comments-list">
                        {
                            comments.map((comment,index) =>{
                                let from = (comment.from == 'admin')?"by-author":"";
                                let reply = (comment.reply.length > 0)?true:false;
                                return(
                                    <li key={index}>
                                        <div className="comment-main-level">
                                            <div className="comment-avatar"><img src={`${process.env.PUBLIC_URL}/bucket/${comment.image}`} alt=""/></div>
                                            <div className="comment-box">
                                                <div className="comment-head">
                                                    <h6 className={`comment-name ${from}`}><a href="#">{comment.name}</a></h6>
                                                    <span>{comment.date}</span>
                                                    <i className="fa fa-reply" onClick={()=>this.setReply(comment.id,comment.name,comment.message.substr(0,40))}></i>
                                                    {/* <i className="fa fa-heart"></i> */}
                                                </div>
                                                <div className="comment-content">
                                                    {parser(comment.message)}
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="comments-list reply-list">
                                            {
                                                reply
                                                &&
                                                [...comment.reply].map((rep,index)=>(
                                                    <Reply reply={rep} key={index} />
                                                ))
                                            }
                                        </ul>
                                        
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="comment-box-sn">
                    {replyMessage(toReply)}
                    <form onSubmit={this.onSubmitForm}>
                        <input type="text" name="text" name="name" placeholder="Enter your name" className="form-control" onChange={this.onChange} value={name}  required/><br/>
                        <input type="email" name="email" placeholder="Enter your E-mail" className="form-control" onChange={this.onChange} value={email} required/>
                        <textarea name="message" onChange={this.onChange} value={message}  placeholder="Write your Comment" required></textarea>
                        <button type="submit" className="btn btn-general btn-green" style={{width: "100%"}} role="button">Send</button>
                    </form>                    
                </div>
            </>
        )
    }
}
